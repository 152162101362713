<template>
    <div class="w-full">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar Contacto</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>
        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
           <div v-for="(contact,index) of contact_info_model" :key = index>

                <div class="flex">

                    <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                        <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal(contact.contact_type,contact)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </div>

                    </div>

                    <div class="w-5/6 py-5 px-5">
                        <div>
                            <div class="text-gray-400">
                                {{ $filters.formatCamelize(contact.contact_type) }} :
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ contact.value }}
                            </div>
                        </div>
                        
                    </div>

                </div>

           </div>
            
        </div>

        <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar Contacto</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="showModal = false">X</label>
                </div>

            </template>
            
            <template v-slot:body>
                <div>
                    <Contact ref="ContactRef" :validations="rulesValidationsContact" :view_class_view="viewClassViewPontact" :show_container="showContainerContact" />
                </div>
            </template>
            
            <template v-slot:footer>
                <button class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                    @click="update_contact">
                    Guardar Cambios
                </button>
            </template>
        </Modal>

    </div>
    </div>
</template>

<script>
import { onMounted, ref, registerRuntimeCompiler } from 'vue';
import { email, helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import Modal from '@/components/Modal/Modal.vue';
import Client from '@/classes/Client';
import { getTokenDecoden } from "@/helpers/tokenauth.js"
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import Loading from '@/components/Loading/VueLoading.vue';

export default {
    name:"UpdateContactCompoent",
    components:{
        Modal,
        Loading
    },
    setup()
    {
        const ContactRef = ref(null);
        const showModal = ref(false);
        const clientObj = ref(null);
        const router = useRouter();
        const isLoading = ref(false);
        const clientClass = ref(null);

        const contact_info_model = ref( 
            [
            {
              "id": 0,
              "contact_type": "celular",
              "value": "",
            },
            {
              "id": 0,
              "contact_type": "correo",
              "value":""
            },
            {
              "id": 0,
              "contact_type": "telefono particular",
              "value":""
            }
            ]
        );

        const model_key = {
            "celular":"cellPhone",
            "correo":"email",
            "telefono particular":"phone"
        };
        
        let showContainerContact = ref({
                phone:false,
                cellPhone:false,
                email:false
            }
        );

        function show_container_off()
        {
            showContainerContact.value.phone = false;
            showContainerContact.value.cellPhone = false;
            showContainerContact.value.email = false;
        }

        function open_modal(edit_key,item)
        {
            show_container_off();
            clean_contact_component();
            set_data_in_component({ value:item.value, key:[model_key[edit_key]] });
            showContainerContact.value[model_key[edit_key]] = true;
            showModal.value = true;
        }

        let rulesValidationsContact = {
            phone:{
                required: helpers.withMessage(`El campo es requerido` , requiredIf(false)),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value==""){
                    return true
                    }

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                    return false
                    }

                    return true
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    if(value.toString().length > 10){
                        return false
                    }
                    return true
                }),
                isDiferentToCell: helpers.withMessage(`El número de teléfono debe ser diferente al número de celular` , function(value, e){
                    if(value == "") return true;

                    var cellphone_number = contact_info_model.value.filter( item => { return item.contact_type == "celular" })[0].value
                    return value.replaceAll(" ","").replaceAll("-","") != cellphone_number
                })
                
            },
            cellPhone:{
                required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){return showContainerContact.value.cellPhone})),
                minLength: helpers.withMessage(`El campo debe contener mínimo 10 caracteres` , function(value){
                    if(value==""){
                        return true
                    }

                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");
                    
                    if(value.toString().length < 10){
                    return false
                    }

                    return true
                }),
                maxLength: helpers.withMessage(`El campo debe contener máximo 10 caracteres` , function(value){
                    value = value.toString().replace("-", "");
                    value = value.toString().replace(" ", "");

                    if(value.toString().length > 10){
                        return false
                    }

                    return true
                }),
                isDiferentToCell: helpers.withMessage(`El número de celular debe ser diferente al número de teléfono` , function(value, e){
                    if(value == "") return true;

                    contact_info_model.value.filter( item => {
                        return item.contact_type == "phone";
                    })

                    var phone_number = contact_info_model.value.filter( item => { return item.contact_type == "phone" })[0].value
                    return value.replaceAll(" ","").replaceAll("-","") != phone_number
                })
            },
            email:{
                email: helpers.withMessage(`El valor no es una dirección de correo electrónico válida` , email), 
                required: helpers.withMessage(`El campo es requerido` , requiredIf(function(){
                    return showContainerContact.value.email;
                })),
                minLength: helpers.withMessage(`El campo debe contener mínimo 5 caracteres` , minLength(5)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 255 caracteres` , maxLength(255)),
                $autoDirty: true 
            }
        };

        let viewClassViewPontact = {
            input_class:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_conteiner_phone:{
                input_mask:"### ###-####"
            },
            div_conteiner_cellPhone:{
                input_mask:"### ###-####"
            },
            div_container:"grid grid-cols-1 my-5",
            label:"label-input w-full block tracking-wide mb-2"
        };

        onMounted( async () => {
            const token_body = getTokenDecoden();
            // clientObj.value = new Client(token_body.obj.person);

            const response = await get_client_data(token_body.obj.person);
            if(!response) return;
            clientObj.value = new Client(response);
            await get_contact_info();

        });

        async function get_client_data({id})
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await Client.get_client_data(id);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return response.data;
        }

        async function get_contact_info()
        {
            isLoading.value = true;
            const response = await clientObj.value.get_contact_info();
            if(!response.status)
            {
                isLoading.value = false;
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text: response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
            }
            isLoading.value = false;
            set_data_model(response.data);
            // set_data_in_component({ cellPhone:response.data.cellphone, email: response.data.email, phone:response.data.phone } );
        }

        function get_info_of_component()
        {
            const response = ContactRef.value.execute_validation();
            if(typeof(response) == "boolean")
            {
                showModal.value = false
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text: "Favor de completar los campos solicitados.",
                    allowOutsideClick:false
                }).then( resp => {
                    if(resp)
                        showModal.value = true;
                });
                return false;
            }
            return ContactRef.value.contacModel;
        }

        async function update_contact()
        {
            isLoading.value = true;
            const response = get_info_of_component();
            if(!response){
                isLoading.value = false;
                return;
            }

            const data_to_update = get_position_info_to_update(response);

            await update_contact_info(data_to_update);

            Swal.fire({
                icon:"success",
                title:"Datos actualizados",
                text:"Los datos de contacto fueron actualizados.",
                allowOutsideClick:false
            }).then( res => {
                if(res.value)
                {
                    router.push("/cuenta/principal");
                }
            })

            isLoading.value = false;
        }

        function get_position_info_to_update(contact_iformation)
        {
            if(!isObject(contact_iformation)) return;

            const node_keys = Object.keys(contact_iformation);

            var key_containing_data = node_keys.filter( element =>{
                return contact_iformation[element] != ""
            })
            
            if(key_containing_data.length == 0){
                key_containing_data = ["phone"]
            }

            const contact_iformation_data = contact_info_model.value.filter( item => {
                return item.contact_type == { "cellPhone":"celular", "email":"correo", "phone":"telefono particular" }[key_containing_data[0]];
            });
            
            contact_iformation_data[0].value = contact_iformation[key_containing_data]; 

            if(key_containing_data[0] != "email")
            {
                contact_iformation_data[0].value = contact_iformation_data[0].value.replaceAll("-","");
                contact_iformation_data[0].value = contact_iformation_data[0].value.replaceAll(" ","");
            }

            return JSON.parse(JSON.stringify(contact_iformation_data));

        }

        async function update_contact_info(data_to_update)
        {
            isLoading.value = true;
            showModal.value = false
            const response = await clientObj.value.update_contact_info(data_to_update);
            console.log(response)
            if(!response.status)
            {
                isLoading.value = false;
                showModal.value = false
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text: response.message,
                    allowOutsideClick:false
                }).then( resp => {
                    if(resp)
                        router.push("/cuenta/principal");
                });
                return false;
            }
            isLoading.value = false;
        }

        function isObject(subject) {
            return typeof subject == "object";
        }

        function clean_contact_component()
        {
            ContactRef.value.contacModel.phone = "";
            ContactRef.value.contacModel.email = "";
            ContactRef.value.contacModel.cellPhone = "";

            ContactRef.value._contacModelValidation$.$reset();
        }

        function set_data_model( array_of_contact ){
            
            if(!isArray(array_of_contact)) return ;

            if(!is_content_data(array_of_contact)) return;

            const response = assing_value_in_model(array_of_contact);
            if(response.length == 0) return;
            
            contact_info_model.value = response
        }

        function isArray(subject) {
            return Array.isArray(subject);
        }

        function is_content_data(array_of_contact)
        {
            if(!isArray(array_of_contact)) return ;

            if(array_of_contact.length == 0)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text: "No hay información de contacto registrado.",
                    allowOutsideClick:false
                });
                return false;
            }
            return true;
        }

        function assing_value_in_model(array_contact_informatrion)
        {
            if(!is_content_data(array_contact_informatrion)) return [];
            
            const contact_info_model_copy = JSON.parse(JSON.stringify(contact_info_model.value));
            let keys = contact_info_model_copy.map( element => { return { "contact_type":element.contact_type }});

            for(const item of array_contact_informatrion)
            {
                for(let index = 0; index < contact_info_model_copy.length; index++)
                {   
                    if(contact_info_model_copy[index].contact_type == item.contact_type)
                    {
                        keys = keys.filter(element => element.contact_type != item.contact_type)
                        contact_info_model_copy[index].value = item.value;
                        contact_info_model_copy[index].id = item.id;
                    }
                }
            }
            
            fill_data_without_value({obj_keys:keys, response:contact_info_model_copy })

            return contact_info_model_copy;
        }

        function fill_data_without_value({obj_keys, response })
        {
            if(obj_keys.length > 0)
                obj_keys.map( key_none_value => {
                response.map(element => {
                        if(key_none_value.contact_type == element.contact_type)
                        {
                            element.value = "N/A"
                        }
                    } )
                })
        }

        function set_data_in_component({ value, key })
        {
            ContactRef.value.contacModel[key] = value;
        }
        
        return {
            showModal,
            open_modal,
            rulesValidationsContact,
            viewClassViewPontact,
            showContainerContact,
            ContactRef,
            isLoading,
            update_contact,
            contact_info_model,
        }

    }
}
</script>