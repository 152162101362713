<template>
    <div class="w-full">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar Dirección</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>

        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
            <div class="flex">

                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                    <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        <div>
                            <div class="text-gray-400">
                                Código Postal:
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ adressModel.postal_code }}
                            </div>
                            
                        </div>

                        <div>
                            <div class="text-gray-400">
                                Colonia:
                            </div>
                            <div class="text-gray-700 uppercase">
                            {{ adressModel.suburb }}
                            </div>
                        </div>

                        <div>
                            <div class="text-gray-400">
                                Calle:
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ adressModel.street }}
                            </div>
                        </div>
                        
                        <div>
                            <div class="text-gray-400">
                                Número Exterior:
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ adressModel.ext_number }}
                            </div>
                        </div>

                        <div>
                            <div class="text-gray-400">
                                Número Interiro:
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ adressModel.int_number == "" ? "---" : adressModel.int_number }}
                            </div>
                        </div>

                        <div>
                            <div class="text-gray-400">
                                Entre Calle:
                            </div>
                            <div class="text-gray-700 uppercase">
                            {{ adressModel.between_street_one }}
                            </div>
                        </div>

                        <div>
                            <div class="text-gray-400">
                                y Calle:
                            </div>
                            <div class="text-gray-700 uppercase">
                                {{ adressModel.between_street_two }}
                            </div> 
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar Dirección</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="showModal = false">X</label>
                </div>
                
            </template>

            
            
            <template v-slot:body>
                <div>
                    <Address ref="ComponentsAddressRef" :view_class_view="view_class_view" :validations="rulevalidations" />
                    <button v-show="!documentHandlerShow" class="bg-cherryColor border-2 px-5 py-4 
                        text-white rounded-md w-full 
                        font-bold text-md focus:outline-none 
                        hover:bg-white hover:text-cherryColor border-cherryColor transition-all" @click="save_adress(false)">
                        Guardar
                    </button>
                </div>
                <div class="mt-5">
                    <div>
                        <p class="text-xl">Cargar Comprobante de Domicilio:</p>
                        <label class="italic text-gray-400 text-lg">No mayor a tres meses</label>
                    </div>
                    <div v-show="documentHandlerShow">
                        <DocumentHandler :documents="listDocuments" :key="refresh" :displayStatusMessage="false" v-on:upload-file="selected_file($event)" v-on:delete-file="deleteFile($event)" class="mt-5" />
                    </div>
                </div>
            </template>
            
            <template v-slot:footer>

                <button v-show="!documentHandlerShow" class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all" @click="show_upload_document">
                    Subir documentos
                </button>

                <button v-show="documentHandlerShow" class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all" @click="save_adress(true)">
                    Guardar
                </button>
                
                <!-- <button class="bg-yellow-400 border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all">
                    Guardar Cambios
                </button> -->
            </template>
        </Modal>

    </div>
    </div>
    
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import { ref,onMounted,watch } from 'vue';
import { helpers, maxLength, minLength, requiredIf } from "@vuelidate/validators";
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import { formatCamelize } from "@/helpers/filters.js";
import Swal from "sweetalert2";
import Address from "@/classes/Address.js";
import Client from '@/classes/Client';
import api from "@/api/api";
import { useRouter } from "vue-router";
import Loading from '@/components/Loading/VueLoading.vue';

export default{
    name:"UpdateAdressComponent",
    components:{
        Modal
    },
    setup(){
        const isLoading = ref(false);
        const router = useRouter();
        const ComponentsAddressRef = ref(null);
        const documentHandlerShow = ref(true);
        const user_data = ref();
        const showModal = ref(false);
        const refresh = ref(0);
        const clientClass = ref();

        const adressModel = ref({
            street:"",
            suburb:"",
            ext_number:"",
            int_number:"",
            postal_code:"",
            between_street_one:"",
            between_street_two:""
        });

        const listDocuments = ref({response:{data:[
            {
                "document_id":0,
                "url":"",
                "abbreviation":"CD",
                "document_name":"",
                "document_type":"Comprobante de domicilio",
                "extension":"",
                "doument_base64":"",
                "required_in":"",
                "optional":false,
                "max_documents_required":1,
                "extension_allowed":".pdf|.PDF|.png|.PNG|.jpeg|.JPEG|.jpg|.JPG"
            }
        ]}});

        const document_upload = ref({})
        
        onMounted( async () => {
            const token_body = getTokenDecoden();
            const response = await get_client_data(token_body.obj.person);
            if(!response) return;
            user_data.value = response;
            clientClass.value = new Client(response);
            const reponse_address = await get_address();
            set_data_component(reponse_address);
            set_data_model(reponse_address);
        })

        async function get_client_data({id})
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await Client.get_client_data(id);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return response.data;
        }

        async function get_address()
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await clientClass.value.get_address();
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return response.data;
        }

        
        function goBack(){
            let pendingDocumentsList = listDocuments.value.response.data.filter((doc)=> doc.optional == false && doc.document_id == 0)
            let nameDocumentsList = pendingDocumentsList.map((doc)=> doc.document_type).join("<br/>")
            if(pendingDocumentsList.length > 0){
                isLoading.value = false;
                showModal.value = false;
                Swal.fire({
                    title: "Aviso",
                    html: "Documentos obligatorios pendientes de subir: <br/><br/><ol>"+nameDocumentsList,
                    icon: "info",
                    showCancelButton: false,
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FEB72B',
                    confirmButtonText: "OK"
                })
                .then((result) => {
                    if (result.value) {
                        showModal.value = true;
                    }
                    else{
                        Swal.fire({
                        title: "Aviso",
                        text: "Es necesario subir el documento solicitado",
                        icon: "info",
                        confirmButtonColor: '#FEB72B',
                        }).then((result) => {
                            if(result.value)
                            showModal.value = true;
                        })
                    }
                });
                return false
            }
            return true;
        }

        function open_modal()
        {
            showModal.value = true;
        }

        const rulevalidations = {
            street: {
                required: helpers.withMessage(`El campo es requerido.`, requiredIf(true)),
                isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.street != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.street): true),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres.`, minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 100 caracteres.`, maxLength(100)),
                we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.street != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.street): true),
                street_one_and_street_two_equal_street: helpers.withMessage(`El valor de: "Calle" no pueden ser igual a "Entre Calle" o "Y Calle"`, (data,index)=> ![index.between_street_one,index.between_street_two].includes(index.street))
            },
            suburb: {
                required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
            },
            ext_number: {
                required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
                maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres.", maxLength(5)),
                minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                onlyNumber: helpers.withMessage("El campo solo acepta números.", (data,index)=> (new RegExp('^[0-9]+$')).test(index.ext_number))
            },
            int_number: {
                required:helpers.withMessage("El campo es requerido.",requiredIf(false)),
                maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres.", maxLength(5)),
                minLength: helpers.withMessage("El campo debe contener mínimo 2 caracteres.", minLength(2)),
                onlyNumberAndLetter: helpers.withMessage("El campo solo acepta letras y números.", (data,index)=> index.int_number == "" ? true : (new RegExp('^[0-9a-zA-Z]+$')).test(index.int_number))
            },
            postal_code: {
                required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
                minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
                maxLength: helpers.withMessage("El campo debe contener máximo 5 caracteres", maxLength(5)),
                onlyNumber: helpers.withMessage("El campo solo acepta números.", (data,index)=> {
                    if(index.postal_code.length != 5){
                        ComponentsAddressRef.value.addressModel.suburb = ""
                    }
                    return (new RegExp('^[0-9]+$')).test(index.postal_code)
                })
            },
            between_street_one: {
                required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
                isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.street != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.between_street_one): true),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres.`, minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 100 caracteres.`, maxLength(100)),
                we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.between_street_one != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.between_street_one): true),
                street_one_and_street_two_equal: helpers.withMessage(`El valor de: "Entre Calle" y el valor: "Y Calle" no pueden ser iguales`, (data,index)=> index.between_street_one != index.between_street_two)
            },
            between_street_two:{
                required:helpers.withMessage("El campo es requerido.",requiredIf(true)),
                isAlphaNumeric:helpers.withMessage(`El campo no admite caracteres especiales.`,(data,index)=>index.between_street_two != ""? !new RegExp(/[^0-9a-zA-ZÀ-ÿ\s-#]+/).test(index.between_street_two): true),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres.`, minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 100 caracteres.`, maxLength(100)),
                we_have_space_white_start_and_finish:helpers.withMessage(`El campo no puede tener espacios en blanco al inicio y al final.`,(data,index)=>index.between_street_two != ""? !new RegExp(/^([\s]{1,})|[-!$%^&*()_+|~=`{}[]:";'<>?,.\/]|([\s]{1,})$/).test(index.between_street_two): true),
                street_one_and_street_two_equal: helpers.withMessage(`El valor de: "Entre Calle" y el valor: "Y Calle" no pueden ser iguales`, (data,index)=> index.between_street_one != index.between_street_two)
            }
        }

        const view_class_view = {
            // div_container:"w-full",
            classInputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container_1 : {
                container:"",
                input_class:"",
                div_container_neightboorhood:{
                container:" sm:mt-0"
                },
            },
            div_container_2 : {
                container:"grid grid-cols-1 sm:grid-cols-3 sm:gap-10 ",
                state:{
                    label : "label-input w-full block tracking-wide mb-2 ",
                    label_information: "label-input w-full block tracking-wide mb-2 text-xl ",
                },
                municipality:{
                    label : "label-input w-full block tracking-wide mb-2 ",
                    label_information: "label-input w-full block tracking-wide mb-2 text-xl ",
                },
                city_address:{
                    label : "label-input w-full block tracking-wide mb-2 ",
                    label_information: "label-input w-full block tracking-wide mb-2 text-xl ",
                }
            },
            div_container_3 : {
                container:"",
                div_container_street:{
                    container:"sm:col-span-2 "
                },
                div_container_exterior_street:{
                    container:""
                },
                div_container_interior_street:{
                    container:""
                }
            },
            div_container_4 : {
                container:"",
                div_container_between_street_address:{
                    container:""
                },
                div_and_street_address:{
                    container:""
                }
            }
        };

        watch(showModal, () => {
            document.querySelector("#upload_file_button_mobile_0_CD").addEventListener("click", () => {
                showModal.value = false;
            });
            document.querySelector("#upload_file_button_0_CD").addEventListener("click", () => {
                showModal.value = false;
            });

            document.querySelector("#delete_file_button_mobile_0_CD").addEventListener("click", () => {
                showModal.value = false;
            });

            document.querySelector("#delete_file_button_0_CD").addEventListener("click", () => {
                showModal.value = false;
            });

            
        });

        function set_data_component(data)
        {
            ComponentsAddressRef.value.addressModel.postal_code = data.postal_code;
            ComponentsAddressRef.value.searsh_suburb();
            ComponentsAddressRef.value.addressModel.suburb = data.suburb.replace(/[^a-zA-Z0-9-áéíóúÁÉÍÓÚ)()]+(.)/g, (m, chr) => " "+chr.toUpperCase());   
            ComponentsAddressRef.value.addressModel.street = data.street;
            ComponentsAddressRef.value.addressModel.ext_number = data.ext_number;
            ComponentsAddressRef.value.addressModel.int_number = data.int_number;
            ComponentsAddressRef.value.addressModel.int_number = data.int_number;
            ComponentsAddressRef.value.addressModel.between_street_one = data.between_street_one;
            ComponentsAddressRef.value.addressModel.between_street_two = data.between_street_two;
        }

        function set_data_model(data)
        {
            adressModel.value.street = data.street; 
            adressModel.value.suburb = data.suburb; 
            adressModel.value.ext_number = data.ext_number; 
            adressModel.value.int_number = data.int_number; 
            adressModel.value.postal_code = data.postal_code; 
            adressModel.value.between_street_one = data.between_street_one; 
            adressModel.value.between_street_two = data.between_street_two; 
        }

        function get_data_of_component()
        {
            isLoading.value = true;
            const response = ComponentsAddressRef.value.execute_validation();
            if(!response)
            {
                showModal.value = false;
                isLoading.value = false;
                Swal.fire(
                    {
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Favor de acompletar los campos correctamente.",
                        allowOutsideClick: false
                    }
                ).then( (response ) => {
                    if(response.value)
                    {
                        showModal.value = true;
                    }
                });
                return false
            }
            isLoading.value = false;

            return response;
        }

        function selected_file(value)
        {
            refresh.value = 0;

            document_upload.value = {
                file:value.newFile.file,
                extension:value.newFile.extension
            }

            listDocuments.value.response.data[0].doument_base64 = value.newFile.file;
            listDocuments.value.response.data[0].extension = value.newFile.extension;
            listDocuments.value.response.data[0].document_id = 1;

            refresh.value = 1;

            showModal.value = true;
        }

        function deleteFile(data)
        {
            refresh.value = 0;

            listDocuments.value.response.data[0].doument_base64 = "";
            listDocuments.value.response.data[0].extension = "";
            listDocuments.value.response.data[0].document_id = 0;
            document_upload.value = {};

            refresh.value = 1;

            showModal.value = true;
        }

        function show_upload_document()
        {
            
            const response = get_data_of_component();
            
            if(!response) return;

        }

        function save_adress(upload_doc = true)
        {
            showModal.value = false;
            isLoading.value = true;

            const esponse = get_data_of_component();
            if(!esponse ) return;
            
            if(upload_doc){
                if(!goBack()) return;

                const response_of_document = upload_document();

                if(!response_of_document) return;
            }

            const response_of_process = update_adress(esponse);
            if(!response_of_process) return;
            

            api.refresh_token();
            isLoading.value = false;
            Swal.fire({
                icon:"success",
                title:"Datos actualizados",
                text:"Los datos de dirección fueron modificados.",
                allowOutsideClick:false
            }).then(response => {
                if(response){
                    router.push("/cuenta/principal");
                }
            });
            
        }

        async function update_adress(adress_data)
        {
            isLoading.value = true;
            const objAdress = new Address(adress_data);
            const response = await objAdress.update({client_id:user_data.value.id });
            if(response.status)
            {
                isLoading.value = false;
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            }
            isLoading.value = true;
            return true;
        }

        async function upload_document()
        {
            isLoading.value = true;
            // const objClient = new Client(user_data.value.obj.person);
            const response = await clientClass.value.upload_proof_of_address_document(document_upload.value);
            if(!response.status)
            {
                isLoading.value = false;
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        // router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;

            return true;
        } 


        return {
            showModal,
            open_modal,
            listDocuments,
            refresh,
            view_class_view,
            rulevalidations,
            ComponentsAddressRef,
            adressModel,
            selected_file,
            deleteFile,
            documentHandlerShow,
            show_upload_document,
            save_adress,
            isLoading
        }
    }
}

</script>

<style>
/*evita que las alertas aparescan atras del modal*/
.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000;
}
</style>