import api from '@/api/api';
const host = process.env.VUE_APP_CCAPI;
const business = process.env.VUE_APP_BUSINESS_FONCABSA;
import Response from './Response';

export default class BankAccountInformation {

    #id = 0;
    #account_number = ""; //000000000000000000
    #account_type = ""; //cuenta clabe
    #bank_name = ""; //BBVA BANCOMER
    #expiration_month = ""; //02
    #expiration_year = ""; //26
    #client_id = 0;

    constructor( { number, account, name, month, year, id, client_id } )
    {
        this.#account_number = number;
        this.#account_type = account;
        this.#bank_name = name;
        this.#expiration_month = month;
        this.#expiration_year = year;
        this.#id = id;
        this.#client_id = client_id;
    }
    
    getNumber = () => this.#account_number;

    getAccount = () => this.#account_type;

    getName = () => this.#bank_name;

    getMonth = () => this.#expiration_month;

    getYear= () => this.#expiration_year;

    setNumber = (account_number) => { this.#account_number = account_number;}

    setAccount = (account_type) => { this.#account_type = account_type;}

    setName = (bank_name) => { this.#bank_name = bank_name; }

    setMonth = (expiration_month) => { this.#expiration_month = expiration_month; }

    setYear = (expiration_year) => { this.#expiration_year = expiration_year; }

    static get_account_info = async({client_id}) =>
    {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_bank_accounts`


        const config = {
        headers: {
            "Content-Type": "application/json",
        }
        };

        const params = {
            token_auth: sessionStorage.getItem("login"),
            client_id: client_id
        }

        try {
            const response = await api.httpGet(apiUrl, config, params); 
            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;
        } catch (error) {
            console.log(error)
            const _response = new Response( { data: error.response.data } );
            _response.assing_messaje();
            _response.status = false;
            
            return _response;
        }
    }

    update_person_bank_account = async () =>
    {
        try
        {
            const recurse = `${host}/${business}/clients/update_person_bank_account`;
            const params = {
                "token_auth": sessionStorage.getItem("login"),
                "client_id":this.#client_id,
                "person_bank_account":{
                    "id":this.#id,
                    "account_number":this.#account_number,
                    "account_type":this.#account_type,
                    "bank_name":this.#bank_name,
                    "expiration_month":this.#expiration_month,
                    "expiration_year":this.#expiration_year
                }
            };

            const config = {
                headers: {
                "Content-Type": "application/json",
                }
            };

            const response =  await api.httpPost(recurse, config, params);
            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;
        }
        catch(ex)
        {
            console.log(ex)
            const _response = new Response( { data: ex.response.data } );
            _response.assing_messaje();
            _response.status = false;
            console.log(_response)
            return  _response;
        }
    }

    static get_all_banks = async() =>
    {
        const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_all_banks`


        const config = {
        headers: {
            "Content-Type": "application/json",
        }
        };

        const params = {
            token_auth: sessionStorage.getItem("login"),
        }

        try {
            const response = await api.httpGet(apiUrl, config, params);
            const _response = new Response( { data: response.data } );
            _response.validate();
            return _response;
        } catch (error) {
            console.log(error)
            const _response = new Response( { data: error.response.data } );
            _response.assing_messaje();
            _response.status = false;
            return _response;
        }
    }


}