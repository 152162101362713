<template>
    <div class="w-full">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar Estado Civil</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>

        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
            <div class="flex">

                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                    <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div>
                        <div class="text-gray-400">
                            Estado Civil:
                        </div>
                        <div class="text-gray-700 uppercase">
                            {{ client_marital_status.status }}
                        </div>
                        <div class="text-gray-400 mt-5">
                            Nombre:
                        </div>
                        <div class="text-gray-700 uppercase">
                            {{full_name}}
                        </div>

                    </div>

                </div>

            </div>
        </div>

        <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar estado civil</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="showModal = false">X</label>
                </div>

            </template>

            <template v-slot:body>
                <div>
                    <MaritalStatus ref="MaritalStatusRef" :validations="rulesValidationsMaritalStatus"
                    :view_class_view="viewClassViewMaritalStatus"
                    :show_container="show_container__marital_status" :buttons_styles="buttons_styles" />
                </div>
            </template>

            <template v-slot:footer>
                <button class="bg-cherryColor border-2 px-5 py-4
                    text-white rounded-md w-full
                    font-bold text-md focus:outline-none
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                    @click="update_marital_status">
                    Guardar Cambios
                </button>
            </template>
        </Modal>

    </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import { helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import { onMounted, ref, watch } from 'vue';
import Swal from "sweetalert2";
import { getTokenDecoden } from "@/helpers/tokenauth.js"
import Client  from "@/classes/Client.js";
import { formatCamelize } from "@/helpers/filters";
import User from "@/classes/User.js";
import { useRouter } from "vue-router";


export default {
    name:"UpdateMaritalStatusComponent",
    components:{
        Modal,
        Loading
    },
    setup(){

        const router = useRouter();
        const isLoading = ref(false);
        const client_marital_status = ref({});
        const clientClass = ref(null);
        const userObjectClass = ref();
        const MaritalStatusRef = ref(null);
        const full_name = ref("");

        const show_aler_rfc = ref(false);
        const isRequiredRfc = ref(true);

        const is_valid_rfc = ref(false);

        const array_inputs_personal_data = ref(
            {
                input_last_name_partner: false,
                input_mothers_last_name_partner: false,
                input_first_name_partner: false,
                input_second_name_partner: false
            }
        )

        onMounted( async () => {
            userObjectClass.value = new User();
            const token_body = getTokenDecoden();
            const response = await get_client_data(token_body.obj.person);
            if(!response) return;
            clientClass.value = new Client(response);
            const response_marital_status  = await get_marital_status();
            if(!response_marital_status) return;
            client_marital_status.value = create_obj_marital_status(response_marital_status);
            set_data_in_component(client_marital_status.value);
            full_name.value = response_marital_status.marital_status == "SOLTERO(A)" ? "N/A" : (get_full_name()).toUpperCase();
        });

        let rulesValidationsMaritalStatus = ref({
            status:{
                required: helpers.withMessage("El campo es requerido" , requiredIf(true))
            },
            regimeOfGoods:{
                required: helpers.withMessage("El campo es requerido" , requiredIf((value, e)=>{


                return e.status == "Casado(a)"? true: false;
                }))
            },
            rfc:{
                required: helpers.withMessage("El campo es requerido" , requiredIf((value, e)=>{
                return e.status == "Casado(a)" ||
                        e.status == "Concubinato"
                        ? true
                        : false;
                })),
                maxlength: helpers.withMessage("El campo tiene que tener máximo 13",maxLength(13)),
                minlength: helpers.withMessage("El campo tiene que tener máximo 13",minLength(13)),
                structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value, e) {

                    if ((e.status == "Casado(a)" || e.status == "Concubinato") && value != "") {
                        const response = Client.rfc_structure_is_correct(e.rfc);
                        return response.status;
                    }
                    return true;
                })),
                isDinstinct: helpers.withMessage("El RFC no puede ser el mismo que el del socio", function(value, e){
                    if ((e.status == "Casado(a)" || e.status == "Concubinato") && value != "") {
                        if(clientClass.value.rfc() == value){
                            return false;
                        }
                    }
                    return true;
                }),
                valida_name_with_rfc:helpers.withMessage("La estructura del R.F.C no concuerda con el nombre proporcionado." , helpers.withAsync(async function (value, e) {
                    return await valida_name_with_rfc_(e);
                })),
                $autoDirty: true
            },
            dateOfBirth:{
                required: helpers.withMessage("El campo es requerido" , requiredIf(false))
            },
            paternalName:{
                required:helpers.withMessage("El campo es requerido.",function (value, e){
                    return ( e.status == "Casado(a)" || e.status == "Concubinato") && e.maternalName == "" && e.paternalName == "" ? false : true;
                }),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
            },
            valida_name_with_rfc:helpers.withMessage("" , helpers.withAsync(async function (value, e) {
                return await valida_name_with_rfc_(e);
            })),
            maternalName:{
                required:helpers.withMessage("El campo es requerido.",function (value, e){
                return ( e.status == "Casado(a)" || e.status == "Concubinato") && e.maternalName == "" && e.paternalName == "" ? false : true;
                }),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
            },
            firstName: {
                required:helpers.withMessage("El campo es requerido.", function (value, e){
                return ( e.status == "Casado(a)" ||
                        e.status == "Concubinato") && value == "" ? false : true;
                }),
                minLength: helpers.withMessage(`El campo debe contener mínimo 2 caracteres`,  minLength(2)),
                maxLength: helpers.withMessage(`El campo debe contener máximo 30 caracteres`,  maxLength(30)),
            },
            secondName:{
                required:helpers.withMessage("El campo es requerido.", function (value, e){
                return true;
                }),
            },
            gender:{
                required:helpers.withMessage("El campo es requerido.",requiredIf((value, e)=>{
                return ( e.status == "Casado(a)" ||
                        e.status == "Concubinato");
                })),
            }
        });

        function is_empty_names({ firstName, secondName, paternalName, maternalName })
        {
            return (firstName != "" || secondName != "") && (paternalName != "" || maternalName != "" )
        }

        async function valida_name_with_rfc_(e)
        {
            if ((e.status == "Casado(a)" || e.status == "Concubinato") &&  e.rfc != "") {

                if(!isRequiredRfc.value) return true;

                if(array_inputs_personal_data.value.input_last_name_partner ||
                array_inputs_personal_data.value.input_mothers_last_name_partner ||
                array_inputs_personal_data.value.input_first_name_partner ||
                array_inputs_personal_data.value.input_second_name_partner )
                return true ;

                const names = {
                    rfc:e.rfc,
                    firstName:e.firstName,
                    secondName:e.secondName,
                    paternalName:e.paternalName,
                    maternalName:e.maternalName,
                }

                if(!is_empty_names(names)) return true ;

                const response = await valisate_rfc(names);

                if(!response && showModal.value == true && e.rfc.length == 13 && e.rfc != "" && is_empty_names({
                    firstName:e.firstName,
                    secondName:e.secondName,
                    paternalName:e.paternalName,
                    maternalName:e.maternalName,}))
                {
                    if(dateRfcIsValid(e.rfc)){
                        exceptionMessageRFC();
                    }
                }

                if(response.data.response.has_errors == false && !is_valid_rfc.value)
                {
                    is_valid_rfc.value = true;
                    MaritalStatusRef.value.reset_validation()
                    MaritalStatusRef.value.execute_validation();
                }

                return response;
            }
            return true;
        }

        function execute_validation(){
            MaritalStatusRef.value.reset_validation()
            MaritalStatusRef.value.execute_validation();
        }
        async function valisate_rfc({rfc, firstName, secondName, paternalName, maternalName})
        {
            let response = await  userObjectClass.value.rfcFormatIsValid({
                rfc: rfc,
                dateBirth:  MaritalStatusRef.value.maritalStatusModel.dateBirth,
                firstName:  firstName,
                secondName: secondName,
                paternalName: paternalName,
                maternalName: maternalName,
            }) .then((res) => {
                return res;
            }) .catch((err) => {
                return false;
            });

            return response;
        }

        function exceptionMessageRFC() {
            showModal.value = false;
            is_valid_rfc.value = false;
            MaritalStatusRef.value.reset_validation()
            MaritalStatusRef.value.execute_validation();
            Swal.fire({
                title:"Los datos de nombre o fecha de nacimiento no coinciden con el RFC",
                html: `¿Es correcto y desea continuar con el registro?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí",
                cancelButtonText: "No",
            }).then((result) => {
                showModal.value = true;
                if (result.value) {
                    isRequiredRfc.value = false;
                    MaritalStatusRef.value.reset_validation()
                    MaritalStatusRef.value.execute_validation();
                    show_aler_rfc.value = true
                } else {
                    isRequiredRfc.value = true;
                }
            });
        }

        let viewClassViewMaritalStatus = {
            input_class:{
              valid:"border-blue-600",
              no_valid:"border-red-600",
              default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none"
            },
            div_container_error:{
              container:"my-2",
              text_error:"text-red-600 text-sm",
            },
            div_container:{
              container:"grid md:grid-cols-3 grid-cols-1 gap-10 mt-10 ml-8",
              label:"w-full RobotoRegular text-base"
            }
        };


        const buttons_styles = {
            buttonSelected:"",
            activeColorBackground: '#72002c !important',
            inactiveColorBackground: '#FFFFFF !important',
            activeColorText: '#FFFFFF !important',
            inactiveColorText: '#72002c !important',
            activeBorderColor: '#72002c',
            inactiveBorderColor: '#72002c'
        }

        const showModal = ref(false);

        watch(showModal, ()=> {
            // document.querySelector("#input_mothers_last_name_partner").addEventListener("focus", () => {
            //     console.log("xd")
            // });
            document.getElementById("input_last_name_partner").addEventListener("blur", (e) => {
                array_inputs_personal_data.value.input_last_name_partner = false;
                execute_validation()
            });
            document.getElementById("input_mothers_last_name_partner").addEventListener("blur", (e) => {
                array_inputs_personal_data.value.input_mothers_last_name_partner = false;
                execute_validation()

            });
            document.getElementById("input_first_name_partner").addEventListener("blur", (e) => {
                array_inputs_personal_data.value.input_first_name_partner = false;
                execute_validation()
            });
            document.getElementById("input_second_name_partner").addEventListener("blur", (e) => {
                array_inputs_personal_data.value.input_second_name_partner = false;
                execute_validation()
            });

            document.getElementById("input_last_name_partner").addEventListener("focus", (e) => {
                array_inputs_personal_data.value.input_last_name_partner = true;
            });
            document.getElementById("input_mothers_last_name_partner").addEventListener("focus", (e) => {
                array_inputs_personal_data.value.input_mothers_last_name_partner = true;

            });
            document.getElementById("input_first_name_partner").addEventListener("focus", (e) => {
                array_inputs_personal_data.value.input_first_name_partner = true;

            });
            document.getElementById("input_second_name_partner").addEventListener("focus", (e) => {
                array_inputs_personal_data.value.input_second_name_partner = true;
            });
        })

        function open_modal()
        {
            showModal.value = true;
        }

        async function get_data_of_component()
        {
            const response = await MaritalStatusRef.value.execute_validation();
            if(!response)
            {
                showModal.value = false;
                Swal.fire(
                    {
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Favor de ingresar los campos solicitados.",
                        allowOutsideClick: false
                    }
                ).then( (response ) => {
                    if(response.value)
                    {
                        showModal.value = true;
                    }
                });
                return false
            }
            return response;
        }

        async function get_marital_status()
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await clientClass.value.get_marital_status();
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;
            return response.data;
        }

        async function get_client_data({id})
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await Client.get_client_data(id);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;
            return response.data;
        }

        function set_data_in_component({ status, regimeOfGoods, dateOfBirth, paternalName, maternalName, firstName, secondName, gender,rfc })
        {
            status = formatCamelize(status);
            MaritalStatusRef.value.maritalStatusModel.status = status;
            MaritalStatusRef.value.maritalStatusModel.rfc = rfc;
            // MaritalStatusRef.value.maritalStatusModel.dateOfBirth = MaritalStatusRef.value.get_birth_day_by_rfc(rfc);
            MaritalStatusRef.value.maritalStatusModel.paternalName = paternalName;
            MaritalStatusRef.value.maritalStatusModel.maternalName = maternalName;
            MaritalStatusRef.value.maritalStatusModel.firstName = firstName;
            MaritalStatusRef.value.maritalStatusModel.secondName = secondName;
            MaritalStatusRef.value.on_change_civil_status();
            MaritalStatusRef.value.RefChooseButtonRegimeOfGoods.changeButton(regimeOfGoods.toLowerCase());
            MaritalStatusRef.value.maritalStatusModel.regimeOfGoods = regimeOfGoods.toLowerCase();
            MaritalStatusRef.value.RefChooseButtonGender.changeButton(gender.toLowerCase());
            MaritalStatusRef.value.maritalStatusModel.gender = gender.toLowerCase();

        }

        function create_obj_marital_status( { marital_status,regime, spouse }){
            if(marital_status == "SOLTERO(A)" || marital_status == 'VIUDO(A)' || marital_status == 'DIVORCIADO(A)' )
            {
                return {
                    status:marital_status,
                    regimeOfGoods: regime,
                    dateOfBirth: "",
                    paternalName: "",
                    maternalName:"",
                    firstName:"N/A",
                    secondName:"",
                    gender:"",
                    rfc:""
                }
            }
            return {
                status:marital_status,
                regimeOfGoods: regime,
                dateOfBirth: "",
                paternalName: marital_status == "SOLTERO(A)" ? "" : spouse.paternal_name,
                maternalName:marital_status == "SOLTERO(A)" ? "" : spouse.maternal_name,
                firstName:marital_status == "SOLTERO(A)" ? "" : spouse.name,
                secondName:marital_status == "SOLTERO(A)" ? "" : spouse.middle_name,
                gender:marital_status == "SOLTERO(A)" ? "" : spouse.gender,
                rfc:marital_status == "SOLTERO(A)" ? "" : spouse.rfc
            }
        }

        function get_full_name() {
            return [
            client_marital_status.value.paternalName,
            client_marital_status.value.maternalName,
            client_marital_status.value.firstName,
            client_marital_status.value.middlesecondName_name].filter(x => x).join(" ");
        };


        async function update_marital_status()
        {
            const response_data_component = await get_data_of_component();
            if(!response_data_component) return;

            console.log(response_data_component)
            const response  = await update_marital_status_service(response_data_component);

            if(!response) return
            Swal.fire({
                    icon:"success",
                    title:"Estado civil!",
                    text:"Datos de estado civil fueron actualizados.",
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
        }

        async function update_marital_status_service(Model)
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await clientClass.value.update_marital_status(Model);
            console.log(response)
            
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;
            return true;
        }

        function dateRfcIsValid(rfc){
            var yearRfc = Number(rfc.substring(4, 6))
            var year = (new Date().getYear()-100)
            var fullYear = ""
            if (yearRfc < year){
                fullYear = "20"+yearRfc
            }else{
                fullYear = "19"+yearRfc
            }

            return !(new Date(fullYear+ "/" + rfc.substring(6, 8) + "/" + rfc.substring(8, 10)).toString().includes('Invalid Date'))
        }


        return {
            rulesValidationsMaritalStatus,
            viewClassViewMaritalStatus,
            showModal,
            open_modal,
            buttons_styles,
            MaritalStatusRef,
            update_marital_status,
            isLoading,
            get_full_name,
            full_name,
            client_marital_status,
            execute_validation
        }
    }
}

</script>