<template>
    <div class="w-full">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar información bancaria</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>

        <div v-show="showButtonAddBankInformation">

            <div class="my-10">
                <p class="text-gray-400 text-center" style="  font-family: 'Roboto';">No se encontró cuenta bancaria</p>
            </div>

            <button class="bg-cherryColor border-2 px-5 py-3 
                text-white rounded-md w-full 
                font-bold text-md focus:outline-none 
                hover:bg-white hover:text-cherryColor border-cherryColor transition-all" @click="showModal = true">
                Agregar tarjeta
            </button>
        </div>

        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5"  v-for="(client,index) of client_banck_information" :key="index" :id="'div_container_info_account_'+index">
            <div class="flex">
                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md" >

                    <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal(client,index)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div class="text-gray-400">
                        Banco:
                    </div>
                    <div class="text-gray-700 uppercase">
                        {{ client.getName() }}
                    </div>
                    <div class="text-gray-400" v-show="client.getAccount()=='Numero de tarjeta'">
                        Número de Tarjeta:
                    </div>
                    <div class="text-gray-700 uppercase" v-show="client.getAccount()=='Numero de tarjeta'" >
                        {{ client.getNumber() }}
                    </div>
                    <div class="text-gray-400" v-show="client.getAccount()=='Cuenta Clabe'">
                        Cuenta CLABE:
                    </div>
                    <div class="text-gray-700 uppercase" v-show="client.getAccount()=='Cuenta Clabe'">
                        {{ client.getNumber() }}
                    </div>
                </div>

            </div>
        </div>

        <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar información bancaria</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="close_modal()">X</label>
                </div>

            </template>
            
            <template v-slot:body>
                <div>
                    <GifCardComponent ref="savingDataRef" :list_banks="list_banks" :validations="rulesValidationsSavingData" :view_class_view="viewClassViewSavingData" />
                    <br>
                    <button v-show="!upload_statement" class="bg-cherryColor border-2 px-5 py-4 
                        text-white rounded-md w-full 
                        font-bold text-md focus:outline-none 
                        hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                        @click="update_bank_information">
                        Guardar Cambios
                    </button>
                    <button v-show="!upload_statement" class="bg-cherryColor border-2 px-5 py-4 my-2
                        text-white rounded-md w-full 
                        font-bold text-md focus:outline-none 
                        hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                        @click="upload_statement = true">
                        Cargar estado de cuenta
                    </button>

                    <div v-show="upload_statement" class="mt-5">
                        <p class="text-xl">Cargar Estado de Cuenta:</p>
                        <label class="italic text-gray-400 text-lg">No mayor a tres meses</label>

                        <DocumentHandler :documents="listDocuments" :key="refresh" :displayStatusMessage="false" v-on:upload-file="uploadFile($event)" v-on:delete-file="deleteFile($event)" class="mt-5" />
                        
                    </div>
                </div>
            </template>
            
            <template v-slot:footer>
                <button v-show="upload_statement" class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                    @click="update_bank_information">
                    Guardar Cambios
                </button>
            </template>
        </Modal>

    </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import { ref,onMounted,watch } from 'vue';
import { helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import BankAccountInformation from '@/classes/BankAccountInformation';
import Client  from "@/classes/Client.js";
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { formatCamelize } from "@/helpers/filters.js"

export default {
    name:"UpdateBankInformationComponent",
    components:{
        Modal,
        Loading  
    },
    setup()
    {
        const router = useRouter();
        const personal_data_of_session = ref({});
        const savingDataRef = ref(null)
        const showModal = ref(false);
        const isLoading = ref(false);
        const client_banck_information = ref([]);
        const index_of_client_banck_information = ref(null);
        const showButtonAddBankInformation = ref(false)

        const upload_statement = ref(true)

        const clientClass = ref(null);


        const list_banks = ref([]);

        const refresh = ref(0);

        const listDocuments = ref({response:{data:[
            {
                "document_id":0,
                "url":"",
                "abbreviation":"edc",
                "document_name":"",
                "document_type":"Estado de Cuenta",
                "extension":"",
                "doument_base64":"",
                "required_in":"1",
                "optional":false,
                "max_documents_required":1,
                "extension_allowed":".pdf|.PDF|.png|.PNG|.jpeg|.JPEG|.jpg|.JPG"
            }
        ]}});

        const activePaymentMethods = {
            "button_payroll_saving_data": false,
            "button_card_charge_saving_data": true
        };

        let viewClassViewSavingData = {
            classInputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor mt-2 "
                
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container_section:{
                container:"w-full",
                label: "text-4xl",
                form: "grid grid-cols-1 gap-2 my-8"
            }
        }

        const buttons_styles = {
            buttonSelected:"",
            activeColorBackground: '#72002c !important',
            inactiveColorBackground: '#FFFFFF !important',
            activeColorText: '#FFFFFF !important',
            inactiveColorText: '#72002c !important',
            activeBorderColor: '#72002c',
            inactiveBorderColor: '#72002c'
        }

        let is_select_method_payment = ref();
        let is_select_account_type = ref();
        let is_select_bank_name = ref();
        let is_select_payment_frecuency = ref();

        let rulesValidationsSavingData = {
            bank_name:{
                required:helpers.withMessage("El campo banco es requerido.",requiredIf(function(data,index)
                {
                    is_select_bank_name.value = index.bank_name;
                    return true;
                })),
            },
            account_type:{
                required: helpers.withMessage("Seleccione el tipo de cuenta.",  requiredIf(function(data,index)
                {
                    is_select_account_type.value = index.account_type;
                    return true;
                })),
            },
            account_number:{
                required:helpers.withMessage( () => is_select_account_type.value == "Numero de tarjeta" ? "El campo número de tarjeta es requerido": "El campo cuenta clabe es requerid", requiredIf((function(data,index)
                {
                    return is_select_method_payment.value == "CargoTarjeta" ? true : false;
                }))),
                minValue:helpers.withMessage(   
                    () => {
                        if( is_select_account_type.value == "Numero de tarjeta" && is_select_bank_name.value == "AMERICAN EXPRESS"){
                            return "El campo debe contener 15 caracteres."
                        } else {
                            if( is_select_account_type.value == "Cuenta Clabe"){
                                return "El campo debe contener 18 caracteres.";
                            } else {
                                return "El campo debe contener 16 caracteres.";
                            }
                        } 
                    },
                    (value)=>{
                        if( is_select_account_type.value == "Numero de tarjeta" && is_select_bank_name.value == "AMERICAN EXPRESS"){
                            return value.toString().length==17;
                        }
                        else{
                            if( is_select_account_type.value =="Cuenta Clabe"){
                                return value.toString().length==18;
                            }
                            else{
                                if( is_select_account_type.value == "Numero de tarjeta"){
                                    return value.toString().length==19;
                                }else{
                                    return true;
                                }
                            }
                        } 
                    }
                ), 
            },
            expiration_year:{
                required:helpers.withMessage("El campo año de vencimiento es requerido.",function(value, e){
                    if(is_select_method_payment.value == "ViaNomina")
                    {
                        return true;
                    }
                    if(e.account_type == "Cuenta Clabe")
                    {
                        return true
                    }
                    if(e.expiration_year == "" || e.expiration_year == "YY")
                    {
                        return false;
                    }
                    return true;
                })
            },
            expiration_month:{
                required:helpers.withMessage("El campo mes de vencimiento es requerido.",function(value, e){
                    if(is_select_method_payment.value == "ViaNomina")
                    {
                    return true;
                    }
                    if(e.account_type == "Cuenta Clabe")
                    {
                    return true
                    }
                    if(e.expiration_month == "" || e.expiration_month == "MM")
                    {
                    return false;
                    }
                    return true;
                })
            }
        }

        const  formatNumber = (value) => {
            var regex = /^[,.]+$/;
            if (regex.test(value) != true) {
                value = value.replace(
                /[^0-9.]+/g,
                ""
                );
            }
            return value;
        }

        function saving_registration_attributes(value){

        }

        function bank_account_attributes(){
            
        }

        async function close_modal(){
            await loadInformation();
            showModal.value = false;
        }

        function open_modal(bank_information,index){
            showModal.value = true;
            set_data_in_component(bank_information);
            index_of_client_banck_information.value = index;
        }

        function set_data_in_component(bank_information)
        {
            var type_account = bank_information.getAccount().toLowerCase().includes("tarjeta") ? "Numero de tarjeta" : "Cuenta Clabe"
            savingDataRef.value.changeAccountType(type_account, false)
            savingDataRef.value.savingModelValidations.bank_name =  bank_information.getName();
            //savingDataRef.value.savingModelValidations.account_type = formatCamelize(bank_information.getAccount());
            savingDataRef.value.savingModelValidations.account_number =  bank_information.getNumber();
            savingDataRef.value.savingModelValidations.expiration_year =  bank_information.getYear();
            savingDataRef.value.savingModelValidations.expiration_month =  bank_information.getMonth();
        }

        onMounted( async() => {
            loadInformation();
        });

        watch(showModal, () => {
            document.querySelector("#upload_file_button_mobile_0_edc").addEventListener("click", () => {
                showModal.value = false;
            });
            document.querySelector("#upload_file_button_0_edc").addEventListener("click", () => {
                showModal.value = false;
            });            
        });

        async function loadInformation(){
            const token_body = getTokenDecoden();
            personal_data_of_session.value = token_body.obj.person;
            client_banck_information.value = await get_account_info();
            list_banks.value = await get_all_banks();
            const response = await get_client_data(personal_data_of_session.value);
            if(!response) return;
            clientClass.value = new Client(response);
        }

        async function get_account_info()
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await BankAccountInformation.get_account_info(personal_data_of_session.value);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    // if(response){
                    //     router.push("/cuenta/principal");
                    // }
                });
                return false;
            };
            isLoading.value = false;
            if(response.data.person_bank_accounts.length == 0){
                showButtonAddBankInformation.value = true;
                return []
            }

            const response_arry_of_bank = create_bank_class(response.data.person_bank_accounts);
            return response_arry_of_bank;
        }


        function create_bank_class(array_of_accounts){
            let arry_of_bank = [];
            const responmse_is_array = isArray(array_of_accounts);
            if(!responmse_is_array) return;

            for(let account_information of array_of_accounts)
            {
                arry_of_bank.push(create_obj(
                    {
                        number:account_information.account_number, 
                        account:account_information.account_type,
                        name:account_information.bank_name,
                        month:account_information.expiration_month,
                        year:account_information.expiration_year,
                        id:account_information.id,
                        client_id: personal_data_of_session.value.client_id
                    }
                ));
            }

            return arry_of_bank;
        }

        function create_obj({number, account, name, month, year, id, client_id})
        {
            return new BankAccountInformation( { number:number,account:account,name:name,month:month,year:year,id:id, client_id:client_id } );
        }

        function isObject(subject) {
            return typeof subject == "object";
        }

        function isArray(subject) {
            return Array.isArray(subject);
        }

        async function update_bank_information()
        {
            isLoading.value = true;
            showModal.value = false
            const response_bank  = get_info_of_component();
            if(!response_bank) return;

            if(upload_statement.value){
                if(!goBack()) return;

                const document ={
                    file: listDocuments.value.response.data[0].doument_base64,
                    extension: listDocuments.value.response.data[0].extension
                }

                if(!(await upload_document(document))) return;

            }
            
            if(client_banck_information.value.length == 0)
            {
                const response_obj = create_obj({
                        number:(response_bank.account_number.replaceAll("-","")),
                        account:response_bank.account_type,
                        name:response_bank.bank_name,
                        month:response_bank.expiration_month,
                        year:response_bank.expiration_year,
                        id:0,
                        client_id:personal_data_of_session.value.client_id
                    }
                );
                if(!update_person_bank_account(response_obj)) return false;
            }
            if(client_banck_information.value.length > 0)
            {
                client_banck_information.value[index_of_client_banck_information.value].setNumber((response_bank.account_number.replaceAll("-",""))); 
                client_banck_information.value[index_of_client_banck_information.value].setAccount(response_bank.account_type);
                client_banck_information.value[index_of_client_banck_information.value].setName(response_bank.bank_name);
                client_banck_information.value[index_of_client_banck_information.value].setMonth(response_bank.expiration_month);
                client_banck_information.value[index_of_client_banck_information.value].setYear(response_bank.expiration_year);

                if(!update_person_bank_account(client_banck_information.value[index_of_client_banck_information.value])) return false;
                index_of_client_banck_information.value = null;
            }

            isLoading.value = true;
            showModal.value = false
            Swal.fire({
                icon:"success",
                title:"Los datos bancarios fueron actualizados",
                allowOutsideClick:false
            }).then( resp => {
                if(resp.value)
                    router.push("/cuenta/principal");
            });

            return true;
        }

        async function update_person_bank_account(bank_obj)
        {
            if(!is_instanceof_bank(bank_obj)) return;
            isLoading.value = true;
            showModal.value = false
            const response = await bank_obj.update_person_bank_account();
            if(!response.status)
            {
                isLoading.value = false;
                showModal.value = false
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then( resp => {
                    if(resp.value)
                        router.push("/cuenta/principal");
                });
                return false;
            }
            isLoading.value = false;
            return true;
        }

        function is_instanceof_bank(bank_obj)
        {
            const response = bank_obj instanceof BankAccountInformation
            if(!response)
            {
                Swal.fire({
                    icon:"warning",
                    title:"La estructura no es válida.",
                    text: response.message,
                    allowOutsideClick:false
                })
                return false;
            }
            return true;
        }


        function get_info_of_component()
        {
            const response = savingDataRef.value.execute_validation();
            if(typeof(response) == "boolean")
            {
                showModal.value = false
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text: "Favor de completar los campos solicitados.",
                    allowOutsideClick:false
                }).then( resp => {
                    if(resp)
                        showModal.value = true;
                });
                return false;
            }
            return response;
        }

        async function get_all_banks()
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await BankAccountInformation.get_all_banks();
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                });
                return false;
            };
            isLoading.value = false;
            return response.data.lista_bancos;
        }

        async function uploadFile(value){
            refresh.value = 0;

            const document ={
                file:value.newFile.file,
                extension:value.newFile.extension
            }

            listDocuments.value.response.data[0].doument_base64 = value.newFile.file;
            listDocuments.value.response.data[0].extension = value.newFile.extension;
            listDocuments.value.response.data[0].document_id = 1;

            refresh.value = 1;

            showModal.value = true;

        }

        async function deleteFile(value){
            
            refresh.value = 0;

            listDocuments.value.response.data[0].doument_base64 = "";
            listDocuments.value.response.data[0].extension = "";
            listDocuments.value.response.data[0].document_id = 0;

            refresh.value = 1;

            showModal.value = true;

        }

        async function upload_document(new_document)
        {
            const response = await clientClass.value.upload_bank_statement_document(new_document);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };

            return true;
        }

        function goBack(){
            let pendingDocumentsList = listDocuments.value.response.data.filter((doc)=> doc.optional == false && doc.document_id == 0)
            let nameDocumentsList = pendingDocumentsList.map((doc)=> doc.document_type).join("<br/>")
            if(pendingDocumentsList.length > 0){
                isLoading.value = false;
                showModal.value = false;
                Swal.fire({
                    title: "Aviso",
                    html: "Documentos obligatorios pendientes de subir: <br/><br/><ol>"+nameDocumentsList,
                    icon: "info",
                    showCancelButton: false,
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FEB72B',
                    confirmButtonText: "OK"
                })
                .then((result) => {
                    if (result.value) {
                        showModal.value = true;
                    }
                    else{
                        Swal.fire({
                        title: "Aviso",
                        text: "Es necesario subir el documento solicitado",
                        icon: "info",
                        confirmButtonColor: '#FEB72B',
                        }).then((result) => {
                            if(result.value)
                            showModal.value = true;
                        })
                    }
                });
                return false
            }
            return true;
        }

        async function get_client_data({id})
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await Client.get_client_data(id);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return response.data;
        }

        return {
            showModal,
            open_modal,
            activePaymentMethods,
            viewClassViewSavingData,
            buttons_styles,
            rulesValidationsSavingData,
            saving_registration_attributes,
            bank_account_attributes,
            savingDataRef,
            listDocuments,
            isLoading,
            update_bank_information,
            list_banks,
            client_banck_information,
            uploadFile,
            showButtonAddBankInformation,
            upload_statement,
            refresh,
            deleteFile,
            close_modal
        }
    }
}
</script>