<template>
  <div class="w-full">
   <Loading :isLoading="isLoading"/>
    <div class="lg:w-1/2 lg:m-auto ">
      <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar datos laborales</h4>
      <div style="display:flex">
        <div class="w-20 bg-yellow-300 h-1"></div>
        <div class="w-full bg-white h-1"></div>
      </div>

      <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
        <div class="flex">

          <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

            <div
              class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center"
              @click="open_modal">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-10 h-10 text-white">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
              </svg>
            </div>

          </div>

          <!--<div class="w-5/6 py-5 px-5">
            <div>
              <div class="text-gray-400">
                Dirección:
              </div>
              <div class="text-gray-700 uppercase" v-if="is_content_data">
                {{ addressClass.get_address() }}
              </div>
              <div class="text-gray-700 uppercase" v-show="!is_content_data">
                N/A
              </div>
            </div>
          </div>-->


          <div class="w-5/6 py-5 px-5">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <div>
                <div class="text-gray-400">
                  Estatus:
                </div>
                <div class="text-gray-700 uppercase">
                  {{ type_laboral_status }}
                </div>
              </div>

              <div v-show="type_occupation != ''">
                <div class="text-gray-400">
                  Ocupación Principal:
                </div>
                <div class="text-gray-700 uppercase">
                  {{type_principal_occupation}}
                </div> 
              </div>
            </div>
          </div>

        </div>
      </div>

      <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'85%'">
        <template v-slot:header>
          <div class="flex">
            <label class="w-5/6 text-2xl text-center">Actualizar datos laborales</label>
            <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer"
              @click="close_modal">X</label>
          </div>

        </template>

        <template v-slot:body>
          <div>
            <WorkingInformation ref="workInfoRef" :selectBusinessWorkInfo="selectBusinessWorkInfo"
              :key_toke_storage="keyTokeStorage" :active_job_type_options="activeJobTypeOptions"
              :active_job_status="activeJobStatus" :view_class_view="viewClassViewWorkInfo"
              :props_form_visibility="props_form_visibility"
              :validations="rulesValidationsWorkInfo" :urlAPI="ccApi" :buttons_styles="buttons_styles" />
          </div>
        </template>

        <template v-slot:footer>
          <button class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                    @click="update_work_information">
            Guardar Cambios
          </button>
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import { onMounted, ref } from 'vue';
import { helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import Client from "@/classes/Client.js";
import { useRouter } from "vue-router";
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import { formatCamelize } from "@/helpers/filters.js";
import Swal from "sweetalert2";
import moment from "moment";
import { isEqualDate, getCurrentDate, formatIsoDate } from '@/helpers/dates.js';
import Address from "@/classes/Address"

export default {
  name: "UpdateWorkInformationComponent",
  components: {
    Modal,
    Loading
  },
  setup() {
    const router = useRouter();

    const workInfoRef = ref(null);
    const showModal = ref(false);
    const clientClass = ref(null);
    const work_information = ref({});
    const ccApi = process.env.VUE_APP_CCAPI;
    const work_place_atributes = ref();
    const contact_info_of_workplace = ref();
    const addressClass = ref(null);

    const is_content_data = ref(false);
    const isLoading = ref(false);

    const type_laboral_status = ref("")
    const type_principal_occupation = ref("")

    const selectBusinessWorkInfo = {
      disabled: false,
      default_value: 'Seleccione una opción'
    };

    let keyTokeStorage = "login";

    const activeJobTypeOptions = {
      button_employee_status_working_information: true,
      button_own_businesss_working_information: true,
      button_education_worker_working_information: true,
      button_health_worker_working_information: false,
    };

    const activeJobStatus = {
      button_active_status_working_information: true,
      button_retired_status_working_information: true,
      button_student_status_working_information: true
    };

    const props_form_visibility = {
      work_place: true,
    }

    let viewClassViewWorkInfo = {
      classInputs:{
        valid:"border-blue-600",
        no_valid:"border-red-600",
        default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
      },
      div_container_error:{
        container:"my-2",
        text_error:"text-red-600",
      },
      div_container_1 :{
        conatiner : "grid lg:grid-cols-2 grid-cols-1 my-8 gap-2",
        div_work_status:"",
        div_work_type:"",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full"
        }
      },
      div_container_2 :{
        conatiner : "grid grid-cols-1 sm:grid-cols-2 sm:gap-10 mt-8"
      },
      div_container_3 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 my-8",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full md:w-56"
        },
        retired:"sm:col-span-3",
        publicCharge:"sm:col-span-3"
      },
      div_container_4 :{
        conatiner : "grid lg:grid-cols-12 grid-cols-1 my-8",
        choose_button_class:{
          container: "block md:flex",
          button_class : "w-full md:w-56"
        },
        retired:"sm:col-span-3",
        public_office_owner:"sm:col-span-3",
        public_office_date_start:"sm:col-span-3",
        public_office_date_end:"sm:col-span-3"
      },
      div_container_5 :{
        conatiner : "grid lg:grid-cols-2 grid-cols-1 my-8 lg:gap-5",
        phone_working_information:"",
        phone_extension:""
      },
      div_container_6 :{
        conatiner : "grid lg:grid-cols-3 grid-cols-1 my-8",
        place_working_information:"lg:col-span-3 col-span-12",
      },
      
    }

    const buttons_styles = {
      buttonSelected: "",
      activeColorBackground: '#72002c !important',
      inactiveColorBackground: '#FFFFFF !important',
      activeColorText: '#FFFFFF !important',
      inactiveColorText: '#72002c !important',
      activeBorderColor: '#72002c',
      inactiveBorderColor: '#72002c'
    }

    let rulesValidationsWorkInfo = {
      work: {
        workStatus:  {
          require: helpers.withMessage("El campo es requerido", requiredIf((value,index) => {
            return index.workStatus == null || index.workStatus || index.workStatus == false ? false : true
          })),
        },
        typeOfWork:  {
          require: helpers.withMessage("El campo es requerido", requiredIf(function (value,index) {
            return index.workStatus == true && index.typeOfWork == "Negocio Propio" ? true : false;
          })),
          minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
          maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres", maxLength(50)),
        },
        workName:  {
          require: helpers.withMessage("El campo es requerido", requiredIf(function (value,index) {
            return (index.workStatus == true && index.typeOfWork == "Negocio Propio") ? true : false;
          })),
          minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
          maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres", maxLength(50)),
        },
        freeWorkCenterName:  {
          require: helpers.withMessage("El campo es requerido", requiredIf(function (value,index) {
            return (index.typeOfWork == "Trabajador de la educacion" && index.workStatus == true) ? true : false;
          })),
          minLength: helpers.withMessage("El campo debe contener mínimo 5 caracteres", minLength(5)),
          maxLength: helpers.withMessage("El campo debe contener máximo 50 caracteres", maxLength(50)),
        },
        workCenter: {
          businessLine: {
            require: helpers.withMessage("El campo es requerido ", requiredIf(function (value,index) {
              if ( (index.workStatus == true && index.typeOfWork == "Empleado") || (index.workStatus && index.typeOfWork == "Negocio Propio") || (index.workStatus && index.typeOfWork == "Trabajador de la salud")) 
              {
                return true;
              } else if (index.workStatus == false) {
                return true;
              }
              return false;
            }))
          },
          workCenter:  {
            require: helpers.withMessage("El campo es requerido", requiredIf(function (value, index) {
                return (index.workCenter.workCenterName != "OTRO" && index.typeOfWork == "Empleado" && index.workStatus == true) ?true : false;
              }))
            },
            workCenterName: {
              require: helpers.withMessage("El campo es requerido", requiredIf(function (value,index) {
                return (index.workCenter.workCenterName == "OTRO" && index.typeOfWork == "Empleado" && index.workStatus == true) ? true : false;
              })),
              minLength: helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como mínimo 5 caracteres", minLength(5)),
              maxLength: helpers.withMessage("El campo nombre del centro de trabajo tiene que tener como máximo 50 caracteres", maxLength(50)),
            },
            unionSection:  {
              require: helpers.withMessage("El campo es requerido", requiredIf(function (value, index) {
              return ((workInfoRef.value.laborInformation.work.workCenter.workCenterName != "OTRO" && workInfoRef.value.laborInformation.work.typeOfWork == "Trabajador de la educacion" && workInfoRef.value.laborInformation.work.workStatus == true) || 
              (workInfoRef.value.laborInformation.work.workCenter.businessLine == "Educacion" && workInfoRef.value.laborInformation.work.workStatus == false)) ? true : false;
            })),
          },
          additional_data: {
            require: helpers.withMessage("El campo es requerido ", requiredIf(false)), // Exclusivo de fonbensocap importante
          },
        },
      },
      telephone: {
        number:  {
          required: helpers.withMessage("El campo teléfono es requerido.", requiredIf(function () {
           if(!workInfoRef.value.laborInformation.work.workStatus || workInfoRef.value.laborInformation.work.workStatus == null)
           {
            return false;
           }
           return true;
          })),
          minLength: helpers.withMessage("El campo teléfono debe contener mínimo 10 dígitos", function (value,index) {
            if (!workInfoRef.value.laborInformation.work.workStatus || workInfoRef.value.laborInformation.work.workStatus == null) {
              return true;
            }

            value = value.toString().replace(" ", "");
            value = value.toString().replace("-", "");

            return (value.toString().length < 10) ? false : true;
          }),
          maxLength: helpers.withMessage("El campo teléfono debe contener máximo 10 dígitos", function (value) {
            value = value.toString().replace(" ", "");
            value = value.toString().replace("-", "");
            return (value.toString().length > 10) ? false : true;
          }),

        },
        lada:  {
          minLength: helpers.withMessage("El campo extensión debe contener mínimo 1 dígitos", minLength(1)),
          maxLength: helpers.withMessage("El campo extensión debe contener máximo 4 dígitos", maxLength(4)),
          numericValidator: helpers.withMessage("El campo extensión solo puede contener números.", (value) => {
            if (value != "") {
              return new RegExp(/^[0-9]+$/).test(value)
            }
            else return true;
          })
        },
      },
      publicCharge: {
        questionOne: {
          required: requiredIf(function () {
            return false;
          })
        },
        questionTwo: {
          required: helpers.withMessage("El campo cargo público ha desempeñado es requerido. ", requiredIf(function (value, e) {
            return (!e.questionOne) ? false : true;
          })),
          minLength: helpers.withMessage("El campo cargo público debe contener mínimo 1 caracteres", minLength(1)),
          maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres", maxLength(50)),
          alphaNumAndDotValidator: helpers.withMessage("El campo cargo público no admite caracteres especiales. ", (value) => {
            if (value != "")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
            else return true;
          }),
          $autoDirty: true
        },
        startDate: {
          required: helpers.withMessage("El campo fecha inicio es requerido.", requiredIf(function (value, e) {
            return (!e.questionOne) ? false : true;
          })),
          dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function (value, e) {
            if (!e.questionOne) return true;
            var currentDate = moment().startOf('day');
            var date = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            return !currentDate.isBefore(date);
          }),
          isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.", function (value, e) {
            if (!e.questionOne) {
              return true;
            }

            var dateSelected = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            var dateEnd = moment(formatIsoDate(e.endDate, '-'), 'YYYY-MM-DD').startOf('day');
            var isAfter = dateSelected.isAfter(dateEnd)
            return !isAfter;
          }),
          equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function (value, e) {
            if (!e.questionOne || e.endDate == "") {
              return true;
            }

            return !(value == e.endDate);
          }),
          $autoDirty: true
        },
        endDate: {
          required: helpers.withMessage("El campo fecha final es requerido.", requiredIf(function (value, e) {
            return (!e.questionOne) ? false : true;
          })),
          dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function (value, e) {
            if (!e.questionOne) return true;
            var currentDate = moment().startOf('day');
            var date = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            return !currentDate.isBefore(date);
          }),
          isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.", function (value, e) {
            if (!e.questionOne) {
              return true;
            }

            var dateSelected = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            var dateEnd = moment(formatIsoDate(e.startDate, '-'), 'YYYY-MM-DD').startOf('day');
            var isBefore = dateSelected.isBefore(dateEnd)
            return !isBefore;
          }),
          $autoDirty: true
        },
      },
      publicChargeFamily: {
        questionOne: {
          required: requiredIf(function () {
            return false;
          })
        },
        questionTwo: {
          required: helpers.withMessage("El campo cargo público ha desempeñado es requerido.", requiredIf(function () {
            return (!workInfoRef.value.laborInformation.publicChargeFamily.questionOne) ? false : true;
          })),
          minLength: helpers.withMessage("El campo cargo público debe contener mínimo 1 caracteres", minLength(1)),
          maxLength: helpers.withMessage("El campo cargo público debe contener máximo 50 caracteres", maxLength(50)),
          alphaNumAndDotValidator: helpers.withMessage("El campo cargo público no admite caracteres especiales. ", (value) => {
            if (value != "")
              return new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/).test(value)
            else return true;
          }),
          $autoDirty: true
        },
        startDate: {
          required: helpers.withMessage("El campo fecha inicio es requerida.", requiredIf(function (value, e) {
            return (!e.questionOne) ? false : true;
          })),
          dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function (value, e) {
            if (!e.questionOne) return true;
            var currentDate = moment().startOf('day');
            var date = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            return !currentDate.isBefore(date);
          }),
          isValid: helpers.withMessage("El campo debe tener una fecha menor a la fecha final.", function (value, e) {
            if (!e.questionOne) {
              return true;
            }

            var dateSelected = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            var dateEnd = moment(formatIsoDate(e.endDate, '-'), 'YYYY-MM-DD').startOf('day');
            var isAfter = dateSelected.isAfter(dateEnd)
            return !isAfter;
          }),
          equal: helpers.withMessage("El campo fecha inicio no puede ser igual a la fecha final.", function (value, e) {
            if (!e.questionOne || e.endDate == "") {
              return true;
            }
            return !(value == e.endDate);
          }),
          $autoDirty: true
        },
        endDate: {
          required: helpers.withMessage("El campo fecha final es requerida.", requiredIf(function (value, e) {
            return (!e.questionOne) ? false : true;
          })),
          dateNotAllowed: helpers.withMessage('La fecha debe ser menor o igual a la fecha actual', function (value, e) {
            if (!e.questionOne) return true;
            var currentDate = moment().startOf('day');
            var date = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            return !currentDate.isBefore(date);
          }),
          isValid: helpers.withMessage("El campo debe tener una fecha mayor a la fecha inicial.", function (value, e) {
            if (!e.questionOne) {
              return true;
            }

            var dateSelected = moment(formatIsoDate(value, '-'), 'YYYY-MM-DD').startOf('day');
            var dateEnd = moment(formatIsoDate(e.startDate, '-'), 'YYYY-MM-DD').startOf('day');
            var isBefore = dateSelected.isBefore(dateEnd)
            return !isBefore;
          }),
          $autoDirty: true
        },
      },
    }


    async function open_modal() {
      showModal.value = true;
    }

    async function close_modal() {
      await loadInformation()
      showModal.value = false;
    }

    function convertDate(date) {
      let newDate = date.split('-')
      return new Date(`${newDate[2]}/${newDate[1]}/${newDate[0]}`);
    }

    onMounted( async () => {
      loadInformation();
    });

    async function loadInformation(){
      const token_body = getTokenDecoden();
      const response = await get_client_data(token_body.obj.person);
      if(!response) return;
      clientClass.value = new Client(response);
      work_information.value = await get_work_info();
      work_place_atributes.value = await get_workplace();
      contact_info_of_workplace.value = await get_contact_info_of_workplace();
      set_data_component(work_information.value,work_place_atributes.value,contact_info_of_workplace.value);
      
      type_laboral_status.value = work_information.value.work_info.laboral_status
      type_principal_occupation.value = work_information.value.work_info.principal_occupation
    }

    async function get_client_data({ id }) {
      isLoading.value = true;
      showModal.value = false;
      const response = await Client.get_client_data(id);
      if (!response.status) {
        Swal.fire({
          icon: "warning",
          title: "Cuidado!",
          text: response.message,
          allowOutsideClick: false
        }).then(response => {
          if (response) {
            router.push("/cuenta/principal");
          }
        });
        return false;
      };
      isLoading.value = false;
      return response.data;
    }

    async function get_workplace() {
      isLoading.value = true;
      showModal.value = false;
      const response = await clientClass.value.get_workplace();
      if (!response.status) {
        Swal.fire({
          icon: "warning",
          title: "Cuidado!",
          text: response.message,
          allowOutsideClick: false
        }).then(response => {
          if (response) {
            router.push("/cuenta/principal");
          }
        });
        return false;
      };
      isLoading.value = false;
      return response.data;
    }

    async function get_contact_info_of_workplace() {
      isLoading.value = true;
      showModal.value = false;
      const response = await clientClass.value.get_contact_info_of_workplace();
      if (!response.status) {
        Swal.fire({
          icon: "warning",
          title: "Cuidado!",
          text: response.message,
          allowOutsideClick: false
        }).then(response => {
          if (response) {
            router.push("/cuenta/principal");
          }
        });
        return false;
      };
      isLoading.value = false;
      return response.data;
    }

    async function get_work_info() {
      isLoading.value = true;
      showModal.value = false;
      const response = await clientClass.value.get_work_info();
      if (!response.status) {
        Swal.fire({
          icon: "warning",
          title: "Cuidado!",
          text: response.message,
          allowOutsideClick: false
        }).then(response => {
          if (response) {
            router.push("/cuenta/principal");
          }
        });
        return false;
      };
      isLoading.value = false;
      return response.data;
    }

    function laboral_status(status){
      switch (status) {
        case "ACTIVO": return true;
        case "JUBILADO": return false;
        case "ESTUDIANTE": return null;
      }
    }

    function set_data_component(data,address,contact)
    {
      
      if(laboral_status(data.work_info.laboral_status))
      {
        create_new_address(address)
      }

      workInfoRef.value.refWorkStatus.changeButton(laboral_status(data.work_info.laboral_status));
      workInfoRef.value.changeLaboralStatus(laboral_status(data.work_info.laboral_status));
      // workInfoRef.value.laborInformation.work.workStatus = data.work_info.laboral_status != "" ? data.work_info.laboral_status == "ACTIVO" ? true: false : null;
      workInfoRef.value.laborInformation.work.workCenter.businessLine = data.work_info.occupation;
      // workInfoRef.value.laborInformation.work.typeOfWork = data.work_info.principal_occupation;
      workInfoRef.value.workOfTypeRef.changeButton(data.work_info.principal_occupation);
      workInfoRef.value.laborInformation.work.workName = data.work_info.business_name;
      workInfoRef.value.addressRef.addressModel.postal_code = address.postal_code;

      workInfoRef.value.addressRef.addressModel.suburb = address.suburb.toUpperCase();
      workInfoRef.value.addressRef.searsh_suburb();

      workInfoRef.value.addressRef.addressModel.street = address.street;
      workInfoRef.value.addressRef.addressModel.ext_number = address.ext_number;
      workInfoRef.value.addressRef.addressModel.int_number = address.int_number;
      
      if(data.work_info.principal_occupation == "Trabajador de la educacion"){
        workInfoRef.value.laborInformation.work.workCenter.unionSection = address.employer
        workInfoRef.value.laborInformation.work.freeWorkCenterName = address.name
      }
      
      if(data.work_info.principal_occupation == "Empleado"){
        console.log("address.name",address.name)
        console.log("workInfoRef.value.laborInformation",workInfoRef.value.laborInformation)
        if(address.name == "GRUPO CABSA"){
          workInfoRef.value.laborInformation.work.workCenter.workCenter = address.name
          workInfoRef.value.laborInformation.work.workCenter.workCenterName = address.name
        }else{
          workInfoRef.value.laborInformation.work.workCenter.workCenter = "OTRO"
          workInfoRef.value.laborInformation.work.workCenter.workCenterName = "pruebaa"
        }
        
      }

      workInfoRef.value.addressRef.addressModel.between_street_one = address.between_street_one;
      workInfoRef.value.addressRef.addressModel.between_street_two = address.between_street_two;

      const contact_info  = contact.filter( element => element.related_type == "workplaces");
      if(contact_info.length > 0)
      {
        workInfoRef.value.laborInformation.telephone.number = contact[0].value;
        workInfoRef.value.laborInformation.telephone.lada = contact[0].extension;
      }

      const headline =  data.public_office.filter( element => element.relationship == "titular");
      const family = data.public_office.filter( element => element.relationship == "familiar");

      if(headline.length > 0 && headline[0].has_had_public_positions)
      {
        workInfoRef.value.refPublicCharge.changeButton(headline[0].has_had_public_positions);
        workInfoRef.value.laborInformation.publicCharge.questionTwo = headline[0].positions_name;
        workInfoRef.value.laborInformation.publicCharge.startDate = moment( headline[0].start_date_public_positions ).format("DD-MM-YYYY");
        workInfoRef.value.laborInformation.publicCharge.endDate = moment(headline[0].end_date_public_positions).format("DD-MM-YYYY");
      }
      if(family.length > 0 && family[0].has_had_public_positions)
      {
        workInfoRef.value.refFamilyPublicCharge.changeButton(family[0].has_had_public_positions)
        workInfoRef.value.laborInformation.publicChargeFamily.questionTwo = family[0].positions_name;
        workInfoRef.value.laborInformation.publicChargeFamily.startDate = moment( family[0].start_date_public_positions ).format("DD-MM-YYYY");
        workInfoRef.value.laborInformation.publicChargeFamily.endDate = moment(family[0].end_date_public_positions).format("DD-MM-YYYY");
      }

      

    }

    function capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    function create_new_address({street, suburb, ext_number, int_number, postal_code, between_street_one, between_street_two, city, state, country, municipality})
    {
      addressClass.value = new Address({street, suburb, ext_number, int_number, postal_code, between_street_one, between_street_two, city, state, country, municipality})
      is_content_data.value = true
    }

    async function update_work_information()
    {
      isLoading.value = true;
      showModal.value = false;

      const work_information = get_data_component();
      if(!work_information) return;

      const z = create_obj_work_info(work_information)
      const x = create_obj_update_workplace(work_information,z.work_info.laboral_status);
      
      if(! await update_work_info(z)) return ;
      if(! await update_workplace(x)) return ;

      if(z.work_info.laboral_status == "ACTIVO")
      {
        const  y = create_obj_update_contact(work_information);
        if(! await update_contact_info_of_workplace(y)) return ;
      }
      isLoading.value = false;

      Swal.fire({
        icon:"success",
        title:"Los datos laborales fueron actualizados.",
        allowOutsideClick:false
      }).then( resp => {
        if(resp.value)
          router.push("/cuenta/principal");
      });
    }

    function get_data_component()
    {
      const response = workInfoRef.value.execute_validation();
      if(typeof(response) == "boolean")
      {
          showModal.value = false
          Swal.fire({
              icon:"warning",
              title:"Cuidado!",
              text: "Favor de a completar los campos como se solicitan.",
              allowOutsideClick:false
          }).then( resp => {
              if(resp)
                  showModal.value = true;
          });
          return false;
      }
      return response;
    }

    async function update_contact_info_of_workplace(contact_info_list)
    {
      const response = await clientClass.value.update_contact_info_of_workplace(contact_info_list);
      if(!response.status)
      {
          isLoading.value = false;
          showModal.value = false
          Swal.fire({
              icon:"warning",
              title:"Cuidado!",
              text:response.message,
              allowOutsideClick:false
          }).then( resp => {
              if(resp.value)
                  router.push("/cuenta/principal");
          });
          return false;
      }
      isLoading.value = false;
      return true;
    }

    async function update_work_info(work_info)
    {
      const response = await clientClass.value.update_work_info(work_info);
      if(!response.status)
      {
          isLoading.value = false;
          showModal.value = false
          Swal.fire({
              icon:"warning",
              title:"Cuidado!",
              text:response.message,
              allowOutsideClick:false
          }).then( resp => {
              if(resp.value)
                  router.push("/cuenta/principal");
          });
          return false;
      }
      isLoading.value = false;
      return true;
    }


    async function update_workplace(contact_info_list)
    {
      const response = await clientClass.value.update_workplace(contact_info_list);
      console.log(response)
      if(!response.status)
      {
        isLoading.value = false;
        showModal.value = false;
        Swal.fire({
            icon:"warning",
            title:"Cuidado!",
            text:response.message,
            allowOutsideClick:false
        }).then( resp => {
            if(resp.value)
                router.push("/cuenta/principal");
        });
        return false;
      }
      isLoading.value = false;
      return true;
    }

    function create_obj_update_workplace(value, status)
    {
      return {
        "between_street_one":status == "ACTIVO"?  value.work_address_info.between_street_one : "",
        "between_street_two": status == "ACTIVO"? value.work_address_info.between_street_two: "",
        "center_key": "-----",
        "country": status == "ACTIVO"? value.work_address_info.country : "",
        "state":status == "ACTIVO"? value.work_address_info.state :"",
        "city":status == "ACTIVO"? value.work_address_info.city: "",
        "municipality":status == "ACTIVO"? value.work_address_info.municipality:"",
        "postal_code":status == "ACTIVO"? value.work_address_info.postal_code: "",
        "ext_number":status == "ACTIVO"? value.work_address_info.ext_number : "",
        "int_number":status == "ACTIVO"? value.work_address_info.int_number : "",
        "street":status == "ACTIVO"? value.work_address_info.street : "",
        "suburb":status == "ACTIVO"? value.work_address_info.suburb : "",
        "employer":status == "ACTIVO"? (value.work_info.work.workCenter.unionSection == 'other')? "-----": value.work_info.work.workCenter.unionSection : "",
        "name": get_name_work(status,value),
        "representative_name": "-----"
      }
    }

    function get_name_work(status,value){
      var name = ""

      if (status == "ACTIVO"){

        if (value.work_info.work.workName != ""){
          name = value.work_info.work.workName
        }

        if (value.work_info.work.freeWorkCenterName != ""){
          name = value.work_info.work.freeWorkCenterName
        }
        workInfoRef.value.laborInformation.work.workCenter.workCenterName
        if (value.work_info.work.workCenter.workCenter != ""){
          if(value.work_info.work.workCenter.workCenter == "OTRO"){
            name = value.work_info.work.workCenter.workCenterName
          }else{
            name = value.work_info.work.workCenter.workCenter
          }
        }

      }

      return name
    }

    function create_obj_update_contact(value)
    {
      const contac_info = contact_info_of_workplace.value.filter( element => element.related_type == "workplaces");
      return {
        "id": contac_info.length > 0 ? contac_info[0].id : 0 ,
        "contact_type": "telefono",
        "value": value.work_info.telephone.number
      }
    }

    function create_obj_work_info(work_info)
    {
      console.log("work_info",work_info)
      return {
        "work_info":{
          "business_name": work_info.work_info.work.workName == "" ? "-----" : work_info.work_info.work.workName,
          "laboral_status":(work_info.work_info.work.workStatus == true) ? 'ACTIVO': (work_info.work_info.work.workStatus==null)? 'ESTUDIANTE':'JUBILADO',
          "occupation": work_info.work_info.work.workCenter.businessLine == "" ? "-----" : work_info.work_info.work.workCenter.businessLine,
          "principal_occupation": work_info.work_info.work.typeOfWork
        },
        "public_office":{
          "titular":{
            "has_had_public_positions": (work_info.work_info.publicCharge.questionOne == "" || work_info.work_info.publicCharge.questionOne == false) ? false : true,
            "positions_name":work_info.work_info.publicCharge.questionTwo,
            "start_date_public_positions":work_info.work_info.publicCharge.startDate,
            "end_date_public_positions":work_info.work_info.publicCharge.endDate
          },
          "familiar":{
            "has_had_public_positions": (work_info.work_info.publicChargeFamily.questionOne == "" || work_info.work_info.publicChargeFamily.questionOne == false) ? false : true,
            "positions_name":work_info.work_info.publicChargeFamily.questionTwo,
            "start_date_public_positions":work_info.work_info.publicChargeFamily.startDate,
            "end_date_public_positions":work_info.work_info.publicChargeFamily.endDate
          }
        }
      }
    }

    return {
      isLoading,
      showModal,
      open_modal,
      ccApi,
      selectBusinessWorkInfo,
      keyTokeStorage,
      activeJobTypeOptions,
      activeJobStatus,
      viewClassViewWorkInfo,
      rulesValidationsWorkInfo,
      workInfoRef,
      buttons_styles,
      update_work_information,
      addressClass,
      is_content_data,
      props_form_visibility,
      close_modal,
      type_laboral_status,
      type_principal_occupation
    }
  }
}

</script>