import api from '@/api/api'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import Response from '../classes/Response';
export default class Client {

  // data = {
  //   "token_auth":"",
  //   "personal_info": {
  //       "identification_type":"",
  //       "identification_number":"",
  //       "residence_type":"",
  //       "residence_date":"",
  //       "education_level":"",
  //       "marital_status":" ",
  //       "regime":"",
  //       "spouse": {
  //         "rfc": "",
  //         "name": "",
  //         "gender": "",
  //         "middle_name": "",
  //         "maternal_name": "",
  //         "paternal_name": "",
  //         "birtday":""
  //       },
  //       "contact_info":{
  //           "0": {
  //             "value": "",
  //             "status": "",
  //             "extension": "",
  //             "contact_type": ""
  //           },
  //           "1": {
  //             "value": "",
  //             "status": "",
  //             "extension": "",
  //             "contact_type": ""
  //           },
  //           "2": {
  //             "value": "",
  //             "status": "",
  //             "extension": "",
  //             "contact_type": ""
  //           }
  //         },
  //       "work_info": {
  //         "status": "",
  //         "category": "",
  //         "occupation": "",
  //         "supervisor": "",
  //         "active_since": "",
  //         "business_name": "",
  //         "laboral_status": "",
  //         "employee_number": [
  //           ""
  //         ],
  //         "principal_occupation": "",
  //         "has_had_public_positions":false,
  //         "any_relative_has_had_public_positions": false
  //       },
  //       "workplace": {
  //         "name": "",
  //         "city": "",
  //         "state": "",
  //         "street": "",
  //         "suburb": "",
  //         "status": "",
  //         "postal_code": "",
  //         "country": "",
  //         "center_key": "",
  //         "ext_number": "",
  //         "int_number": "",
  //         "municipality": "",
  //         "adress": {
  //           "city": "",
  //           "state": "",
  //           "status": "",
  //           "street": "",
  //           "suburb": "",
  //           "country": "",
  //           "latitude": "",
  //           "longitude": "",
  //           "ext_number": "",
  //           "int_number": "",
  //           "postal_code": "",
  //           "municipality": "",
  //           "residence_start": "",
  //           "residence_status": "",
  //           "between_street_one": "",
  //           "between_street_two": ""
  //         }
  //       },
  //       "salary_info": {
  //         "liquidity": 0,
  //         "monthly_income": 0,
  //         "income_frecuency": ""
  //       }
  //     },
  //   "documents_list": [
  //     {
  //     "name":"",
  //         "extension":"",
  //         "abbreviation":"",
  //         "credit_request_id": 0,
  //         "remote_folder":"",
  //         "file":""
  //       }
  //     ],
  //   "credit_request_info": {
  //     "amount":"",
  //     "term":"",
  //     "payment_periodicity":"",
  //     "credit_destination":"",
  //     "payment_method":""
  //   },
  //   "credit_owner_info":{
  //     "credit_benefit_statement":"",
  //     "rfc":"",
  //     "name":"",
  //     "middle_name":"",
  //     "paternal_name":"",
  //     "maternal_name":"",
  //     "gender":"",
  //     "nationality":"",
  //     "country":"",
  //     "state":"",
  //     "city":"",
  //     "curp":"",
  //     "email":"",
  //     "cellphone":"",
  //     "phone":"",
  //     "has_had_public_positions":false,
  //     "any_relative_has_had_public_positions":false
  //   },
  //   "credit_provider_info": {
  //     "credit_benefit_statement":"",
  //     "rfc":"",
  //     "name":"",
  //     "middle_name":"",
  //     "paternal_name":"",
  //     "maternal_name":"",
  //     "gender":"",
  //     "nationality":"",
  //     "country":"",
  //     "state":"",
  //     "city":"",
  //     "curp":"",
  //     "email":"",
  //     "cellphone":"",
  //     "phone":"",
  //     "has_had_public_positions":false,
  //     "any_relative_has_had_public_positions":false
  //   }
  // }

  #birthday = "";
  #name = "";
  #middle_name = "";
  #paternal_name = "";
  #maternal_name = "";
  #rfc = "";
  #id = "";

  constructor( { birthday = "", name = "", middle_name = "", paternal_name = "", maternal_name = "", rfc = "", id = 0 } )
  {
    this.#birthday = birthday;
    this.#name = name;
    this.#middle_name = middle_name;
    this.#paternal_name = paternal_name;
    this.#maternal_name = maternal_name;
    this.#rfc = rfc;
    this.#id = id;
  }

  rfc = () => {
    return this.#rfc;
  }

  getClients = async (tokenAuth, fullName) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/fonsnte_person/get_full_name_and_adress_location`
    const apiUrl = `${host}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: sessionStorage.getItem("login"),
      full_name: fullName
    }
    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response.data.mensaje;
    }
  }

  getApplicantInfoToQuoteGroupLifeByRfc = async (tokenAuth, rfc) => {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/person/get_applicant_info_to_quote_group_life_by_rfc`
    const apiUrl = `${host}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: tokenAuth,
      rfc: rfc
    }

    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.data.response.message;
    }
  }

  get_full_name_from_session_storage() {return [this.#name,this.#middle_name,this.#paternal_name,this.#maternal_name].filter(x => x).join(" ") };

  update_rfc = async( new_rfc ) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_rfc`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        "rfc":new_rfc,
        client_id: this.#id
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };
      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      // _response.status = true;
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  upload_proof_of_tax_situation_document = async (new_document) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/upload_proof_of_tax_situation_document`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        document:new_document
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  upload_proof_of_address_document = async(new_document) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/upload_proof_of_address_document`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        document:new_document
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  upload_identification_document = async(new_document) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/upload_identification_document`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        documents:new_document
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  update_contact_info = async(contact_info_list) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_contact_info`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        contact_info_list:contact_info_list
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }
  
  get_contact_info = async () =>
  {
    try 
    {
      const recurse = `${host}/${business}/client/get_contact_info`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id.toString(),
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
      
    } catch (error) {
      console.log(error)
      const _response = new Response( { data: error.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  get_identification_data = async () =>
  {
    const resource = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_identification_data`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: sessionStorage.getItem("login"),
      client_id: this.#id
    }
    try {
      const response =  await api.httpGet(resource, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    } catch (error) {
      console.log(error)
      const _response = new Response( { data: error.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  static rfc_structure_is_correct = (rfc) => {
    rfc = rfc.toUpperCase();
    let rfc_pattern = "^(([A-ZÑ&]{4})([0-9]{2})([0][13578]|[1][02])(([0][1-9]|[12][\\d])|[3][01])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{4})([0-9]{2})([0][13456789]|[1][012])(([0][1-9]|[12][\\d])|[3][0])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{4})([02468][048]|[13579][26])[0][2]([0][1-9]|[12][\\d])([A-Z0-9]{3}))|" +
                        "(([A-ZÑ&]{4})([0-9]{2})[0][2]([0][1-9]|[1][0-9]|[2][0-8])([A-Z0-9]{3}))$";
    
    const coincidences = rfc.match(rfc_pattern);
    const data = [];
    
    if (coincidences != null) {
      coincidences.forEach((element) => {
        if (typeof element != "undefined") {
          data.push(element);
        }
      });
    }
    return coincidences == null
      ? { status: false, coincidences: data }
      : { status: true, coincidences: data };
  }

  static get_client_data = async (id) =>
  {
    const resource = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/get_client_data`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: sessionStorage.getItem("login"),
      person_id: id
    }
    try {
      const response =  await api.httpGet(resource, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    } catch (error) {
      console.log(error)
      const _response = new Response( { data: error.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  get_marital_status = async () =>
  {
    try
    {
      const recurse = `${host}/${business}/client/get_marital_status`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  update_marital_status = async (new_obj_of_marital_status) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_marital_status`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        "marital_status": new_obj_of_marital_status.status.toUpperCase(),
        "regime": new_obj_of_marital_status.regimeOfGoods.toUpperCase(),
        "spouse":{
          "rfc":new_obj_of_marital_status.rfc,
          "name":new_obj_of_marital_status.firstName,
          "middle_name":new_obj_of_marital_status.secondName,
          "paternal_name":new_obj_of_marital_status.paternalName,
          "maternal_name":new_obj_of_marital_status.maternalName,
          "gender":new_obj_of_marital_status.gender
        }
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex.response)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      console.log(_response)
      return  _response;
    }
  }

  upload_bank_statement_document = async (new_document) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/upload_bank_statement_document`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        document:new_document
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }

  }

  get_work_info = async () =>
  {
    try
    {
      const recurse = `${host}/${business}/client/get_work_info`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  get_address = async () =>
  {
    try
    {
      const recurse = `${host}/${business}/client/get_address`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }
  
  get_workplace = async () => 
  {
    try
    {
      const recurse = `${host}/${business}/client/get_workplace`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  get_contact_info_of_workplace = async () => 
  {
    try
    {
      const recurse = `${host}/${business}/client/get_contact_info_of_workplace`;
      
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpGet(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  update_contact_info_of_workplace = async (contact_info_list) => 
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_contact_info_of_workplace`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        contact_info_list:[contact_info_list]
      };

      // "contact_info_list": [
      //   {
      //   "id": 308,
      //   "contact_type": "telefono",
      //   "value": "6221032384"
      //   }

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }

   
  }

  update_workplace = async (contact_info_list) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_workplace`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        workplace:contact_info_list
      };

      // "contact_info_list": [
      //   {
      //   "id": 308,
      //   "contact_type": "telefono",
      //   "value": "6221032384"
      //   }

      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }


  update_work_info = async (work_info) =>
  {
    try
    {
      const recurse = `${host}/${business}/clients/update_work_info`;
      const params = {
        "token_auth": sessionStorage.getItem("login"),
        client_id: this.#id,
        work_info:work_info.work_info,
        public_office:work_info.public_office
      };
      
      const config = {
        headers: {
          "Content-Type": "application/json",
        }
      };

      const response =  await api.httpPost(recurse, config, params);
      const _response = new Response( { data: response.data } );
      _response.validate();
      return _response;
    }
    catch(ex)
    {
      console.log(ex)
      const _response = new Response( { data: ex.response.data } );
      _response.assing_messaje();
      _response.status = false;
      
      return _response;
    }
  }

  signUp = async (data) => {
    const config = { 
      "Content-Type": "application/json"
    };

    return await api.httpPost(`${host}/${business}/signup`, config, data);
  }
  
}