<template>
    <div class="w-full">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar Identificación</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>

        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
            <div class="flex">

                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                    <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div>
                        <div class="text-gray-400">
                            {{identification_data.identification_type}}:
                        </div>
                        <div class="text-gray-700 uppercase">
                            {{identification_data.identification_number}}
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <Modal v-show="showModal" :heightContainer="'30%'" :widthContainer="'20%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar Identificación</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="showModal = false">X</label>
                </div>
            </template>
            
            <template v-slot:body>
                <div>
                    <IdentityVerification
                        ref="IdentityVerificationRef"
                        v-if="matiButton.show" 
                        v-on:verification_identity="changeIdentityVerification($event)" 
                        :view_class_view="{div_content_button: 'mt-8'}" 
                        :matiClientId="matiClientId" 
                        :matiClientSecretId="matiClientSecretId" 
                        :matiuserid="matiClientUserId" 
                        :admissionId="matiButton.rfc" 
                        business="foncabsa" 
                        tableType="person" />
                </div>

            </template>
            
            <template v-slot:footer>
                <!-- <button class="bg-yellow-400 border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-yellow-400 border-yellow-400 transition-all">
                    Guardar Cambios
                </button> -->
            </template>
        </Modal>

    </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import { ref, onMounted, watch } from 'vue';
import { getTokenDecoden } from "@/helpers/tokenauth.js"
import Loading from '@/components/Loading/VueLoading.vue';
import api from "@/api/api";
import Swal from "sweetalert2";
import Metamap from "@/classes/Metamap";
import Client from "@/classes/Client";
import { useRouter } from "vue-router";

export default {
    name:"UpdateINEComponent",
    components:{
        Modal,
        Loading
    },
    setup()
    {
        const is_upload_document = ref(); 
        const router = useRouter();

        const matiClientId = process.env.VUE_APP_MATI_CLIENTID;
        const matiClientUserId = process.env.VUE_APP_MATI_UPDATE_ID;

        const isLoading = ref(false);
        const metamapObjectClass = ref(null);
        const identification_data = ref(
            {
                identityId:"",
                verificationId:"",
                identification_type:""
            }
        );

        const user_data = ref({});
        const showModal = ref(false);

        const matiButton = ref({
            show: true,
            rfc: ''
        });

        const ClientObj = ref(null);

        onMounted( async () => {
            metamapObjectClass.value = new Metamap();
            const token_body = getTokenDecoden();

            matiButton.value.rfc = token_body.obj.person.rfc;
            user_data.value = token_body.obj.person;
            ClientObj.value = new Client(  {
                    birthday:"", 
                    name:user_data.value.name,
                    middle_name:user_data.value.middle_name,
                    paternal_name:user_data.value.paternal_name,
                    maternal_name:user_data.value.maternal_name,
                    rfc:user_data.value.maternal_name.rfc,
                    id:user_data.value.client_id 
                }
            );

            const response = await get_identification_data();
            if(!response.status) return;
            
            set_identification_value(response.data);
        });



        function set_identification_value( { identification_type, identification_number })
        {
            identification_data.value.identification_type = identification_type;
            identification_data.value.identification_number = identification_number;
        }

        function open_modal()
        {
            showModal.value = true;
        }

        async function changeIdentityVerification(data)
        {
            is_upload_document.value = data;
        }

        watch( () => is_upload_document.value,  async (value) =>{
            upload_document_ine(value);
        });

        async function upload_document_ine(data)
        {
            showModal.value = false;
            isLoading.value = true;
            
            if(!(await check_identity_verification( data.detail.verificationId))) return ;
            if(!(await get_document_meta_map(data.detail.verificationId))) return;
            if(!(await upload_document())) return ;

            api.refresh_token();
            isLoading.value = false;
            Swal.fire({
                icon:"success",
                title:"Documentos actualizados",
                text:"Los documentos del cliente fueron modificados.",
                allowOutsideClick:false
            }).then(response => {
                if(response){
                    router.push("/cuenta/principal");
                }
            });
        }

        async function check_identity_verification(identity_id)
        {
            const response = await metamapObjectClass.value.check_identity_verification( { verification:identity_id, rfc:matiButton.value.rfc });
            if(!response.status || response.data.identityStatus != "verified")
            {
                isLoading.value = false;
                Swal.fire()
                {
                    Swal.fire({
                        title: "Cuidado!",
                        icon: "warning",
                        text: response.message
                    }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                }
                return false;
            }

            return true;
        }

        async function get_document_meta_map(identity_id)
        {
            const response = await metamapObjectClass.value.getVerificationData(identity_id);
            if(!response.status || response.data.response.errors_of_verification.length>0)
            {
                var msg = response.message == "" ? response.data.response.errors_of_verification[0] : response.message
                isLoading.value = false;
                Swal.fire({
                    title: "Cuidado!",
                    icon: "warning",
                    text: msg
                }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                return false;
            }
            var arrayHashIdentificationDocuments = [];
            var image1 = response.data.response.result.documents[0].photos[0];
            var image2 = response.data.response.result.documents[0].photos[1];
            
            arrayHashIdentificationDocuments.push({
                "file": image1,
                "extension": "png"
            });
            arrayHashIdentificationDocuments.push({
                "file": image2,
                "extension": "png"
            });

            sessionStorage.setItem('ine-documents', JSON.stringify(arrayHashIdentificationDocuments));

            return true;
        }

        const getBase64FromUrl = async (url) => {
            const data = await fetch(url);
            const blob = await data.blob();
            return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); 
                    reader.onloadend = () => {
                    const base64data = reader.result;   
                    resolve(base64data);
                }
            });
        }

        async function upload_document()
        {
            const response = await ClientObj.value.upload_identification_document(JSON.parse(sessionStorage.getItem("ine-documents")));
            sessionStorage.removeItem("ine-documents");
            if(!response.status)
            {
                isLoading.value = false;
                Swal.fire({
                    title: "Cuidado!",
                    icon: "warning",
                    text: response.message
                }).then(response => {
                        if(response){
                            router.push("/cuenta/principal");
                        }
                    });
                return false;
            }

            return true;
        }
        

        async function get_identification_data()
        {
            const response = await ClientObj.value.get_identification_data();
            if(!response.status)
            {
                Swal.fire({
                    title: "Cuidado!",
                    icon: "warning",
                    text: response.message
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });

                return false;
            }

            return response;
        }

        return {
            showModal,
            open_modal,
            // identificationRegisterData,
            matiClientId,
            matiClientUserId,
            matiButton,
            changeIdentityVerification,
            identification_data,
            isLoading
        }
    }
}
</script>