<template>
    <html>
      <body class="" style="background: #FFF">
          <HeaderMobil />
          <MenuLateralMobile/>
          <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
            <MenuLateral/>
            <div class="col-span-6" style="background:#FFF;padding:20px;">
              <component ref="moduleRef" v-if="component_key!=''" v-bind:is="component_key"></component>
            </div>
          </div>
      </body>
    </html>
</template>

<script>
import UpdateRFCComponent from "@/components/Cuenta/UpdatePersonalData/UpdateRFCComponent.vue";
import UpdateINEComponent from "@/components/Cuenta/UpdatePersonalData/UpdateINEComponent.vue";
import UpdateAdressComponent from "@/components/Cuenta/UpdatePersonalData/UpdateAdressComponent";
import UpdateMaritalStatusComponent from "@/components/Cuenta/UpdatePersonalData/UpdateMaritalStatusComponent";
import UpdateWorkInformationComponent from "@/components/Cuenta/UpdatePersonalData/UpdateWorkInformationComponent.vue";
import UpdateBankInformationComponent from "@/components/Cuenta/UpdatePersonalData/UpdateBankInformationComponent.vue"
import UpdateContactCompoent from "@/components/Cuenta/UpdatePersonalData/UpdateContactCompoent.vue";

import HeaderMobil from "../../components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import { onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
    name:"UpdatePersonalDataView",
    components:{
        UpdateRFCComponent,
        UpdateINEComponent,
        UpdateAdressComponent,
        UpdateMaritalStatusComponent,
        UpdateWorkInformationComponent,
        UpdateBankInformationComponent,
        UpdateContactCompoent,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral
    },
    setup(){

        const components_keys = {
            "rfc":"UpdateRFCComponent",
            "update-ine":"UpdateINEComponent",
            "adress":"UpdateAdressComponent",
            "marital-status":"UpdateMaritalStatusComponent",
            "work-informarion":"UpdateWorkInformationComponent",
            "bank-information":"UpdateBankInformationComponent",
            "contact":"UpdateContactCompoent"
        }

        const render = ref(false)

        const  component_key = ref("");

        const route = useRoute();
        const router = useRouter();

        const  getFullPath = () => route.params.component;

        let category = computed(()=> {
            return getFullPath()
        });

        watch(category, async (value)=> {
            if(value == undefined || value == ""){
                // isLoading.value = false;
                return;
            }

            const component_name = searsh_component_name(value);
            loand_component(component_name);
            // await loand_component();
        });

        

        onMounted( ()=> {
            const key = getFullPath();
            const component_name = searsh_component_name(key);
            loand_component(component_name);
        })

        const searsh_component_name = (key) => components_keys[key] == undefined ? "El modulo no existe" : components_keys[key];

        function loand_component(component_name)
        {
            if(component_name == "El modulo no existe")
            {
                Swal.fire(
                    {
                        icon:"warning",
                        title:"Cuidado!",
                        allowOutsideClick:false,
                        text:"El módulo que desa acceder no existe."
                    }
                ).then( (res) => {
                    if(res.value)
                    {
                        router.push({path: '/cuenta/principal'});
                    }
                });
                return
            }
            render.value = !render.value
            component_key.value = component_name;
        }

        return {
            component_key,
            render
        }
    }
}
</script>