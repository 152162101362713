import validations from '@/helpers/validations'
import api from '@/api/api'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

export default class User {
  validateHasOnlyLetters(value) {
    const regex = /^[a-zA-ZÀ-ÿ\s]+$/;
    return validations.validateRegex(value, regex);
  }

  validateHasOnlyLettersAndNumbers(value) {
    const regex = /^[a-zA-ZÀ-ÿ0-9\s]+$/;
    return validations.validateRegex(value, regex);
  }

  static matchesCurpWithRfc(rfc, curp) {
    return validations.matchesCurpWithRfc(rfc, curp);
  }

  async rfcAlreadyExists(tokenAuth, rfc) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/person/is_already_registered`
    const apiUrl = `${host}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: tokenAuth,
      rfc: rfc
    }

    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response;
    }
  }

  async rfcFormatIsValid({rfc, dateBirth, firstName, secondName, paternalName, maternalName}) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/rfc_validator`
    const apiUrl = `${host}${resource}`

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      rfc: rfc,
      birthday: dateBirth,
      name: firstName,
      middle_name: secondName,
      paternal_name: paternalName,
      maternal_name: maternalName
    }

    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response;
    }
  }

  async getListRelationship(tokenAuth) {
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/get_list_kinship`;
    const apiUrl = `${host}${resource}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };

    const params = {
      token_auth: tokenAuth
    }

    try {
      return await api.httpGet(apiUrl, config, params); 
    } catch (error) {
      throw error.response;
    }
  }

  generate_structure_date_and_rfcType(dateBirthDay){
    const dateSplit = dateBirthDay.split("/");
    const day = dateSplit[0];
    const month = dateSplit[1];
    const year = (dateSplit[2]).substr(2,3);
    return `${year}${month}${day}`;

  }
}