<template>
     <div class="w-ful">
        <div class="lg:w-1/2 lg:m-auto ">
        <Loading :isLoading="isLoading" :key="isLoading.toString()" />
        <h4 class="text-2xl text-left text-cherryColor" style="  font-family: 'Roboto';">Actualizar R.F.C</h4>
        <div style="display:flex">
            <div class="w-20 bg-yellow-300 h-1"></div>
            <div class="w-full bg-white h-1"></div>
        </div>
        <div class="w-full drop-shadow-xl border-2 rounded-md mt-5">
            <div class="flex">

                <div class="w-1/6 bg-green-600 md:bg-white flex justify-items-center items-center rounded-md">

                    <div class="h-16 w-16 rounded-full bg-green-600 hover:bg-green-800 transition-all delay-150 m-auto flex justify-center items-center" @click="open_modal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>

                </div>

                <div class="w-5/6 py-5 px-5">
                    <div>
                        <div class="text-gray-400">
                            R.F.C:
                        </div>
                        <div class="text-gray-700 uppercase">
                            {{ model_update_person.rfc }}
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <Modal v-show="showModal" :heightContainer="'90%'" :widthContainer="'40%'">
            <template v-slot:header>
                <div class="flex">
                    <label class="w-5/6 text-2xl text-center">Actualizar R.F.C</label>
                    <label id="button-close-modal" class="w-1/6 flex justify-end hover:text-2xl cursor-pointer" @click="showModal = false">X</label>
                </div>

            </template>
            
            <template v-slot:body>
                <div>
                    <div>
                        <div>
                            R.F.C:
                        </div>
                        <div class="w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none flex"
                        :class="{
                                'border-red-700':
                                model_validation.rfc.$error && model_validation.rfc.$dirty,
                                'border-blue-500': !model_validation.rfc.$invalid,
                            }">
                            {{ rfc_for_view }}
                         <input type="text" class="font-bold uppercase" :maxlength="3"
                         @blur="concat_homoclave_with_rfc($event.target.value)">
                        </div>
                        <p class="text-xs">NOTA: solo es posible modificar la homoclave</p>
                        <div class="my-2" v-for="(error, index) of model_validation.rfc.$errors" :key="index">
                            <div class="text-red-600">{{ error.$message }}</div>
                        </div>
                    </div>
                    <DocumentHandler :documents="listDocuments" :key="refresh" v-on:upload-file="uploadFile($event)" v-on:delete-file="deleteFile($event)" class="mt-5" />
                </div>
            </template>
            
            <template v-slot:footer>
                <button class="bg-cherryColor border-2 px-5 py-4 
                    text-white rounded-md w-full 
                    font-bold text-md focus:outline-none 
                    hover:bg-white hover:text-cherryColor border-cherryColor transition-all"
                    @click="showModal = false">
                    Cerrar
                </button>
            </template>
        </Modal>

    </div>
     </div>
</template>

<script>
import Modal from '@/components/Modal/Modal.vue';
import Loading from '@/components/Loading/VueLoading.vue';
import { helpers, requiredIf, maxLength, minLength } from "@vuelidate/validators";
import { onMounted, ref, watch } from 'vue';
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import Swal from 'sweetalert2';
import User from "@/classes/User";
import Client  from "@/classes/Client.js";
import { useRouter } from "vue-router";
import api from "@/api/api"
import useVuelidate from "@vuelidate/core";

// import Swal from "sweetalert2";

export default {
    name:"UpdateRFCComponent",
    components:{
        Modal,
        Loading
    },
    setup()
    {
        const router = useRouter();
        const showModal = ref(false);
        const isLoading = ref(false);

        const clientClass = ref(null);
        const userObjectClass = ref(new User());

        const refresh = ref(0);

        const listDocuments = ref({response:{data:[
            {
                "document_id":0,
                "url":"",
                "abbreviation":"rfc",
                "document_name":"",
                "document_type":"Constancia de situación Fiscal",
                "extension":"",
                "doument_base64":"",
                "required_in":"",
                "optional":false,
                "max_documents_required":1,
                "extension_allowed":".pdf|.PDF|.png|.PNG|.jpeg|.JPEG|.jpg|.JPG"
            }
        ]}});

        let rulesValidation = {
            rfc:{
                required:helpers.withMessage("El campo rfc es requerido.",requiredIf(true)),
                minLength: helpers.withMessage("El campo debe contener mínimo 13 caracteres", minLength(13)),
                maxLength: helpers.withMessage("El campo debe contener máximo 13 caracteres", maxLength(13)),
                structureIsValid: helpers.withMessage("El formato del RFC no es correcto. Ejemplo de formato correcto: LOMP8206281H0" , helpers.withAsync(async function (value, e) {
                    const response = Client.rfc_structure_is_correct(model.value.rfc);
                    return response.status;
                })),
                alreadyExists: helpers.withMessage("El RFC capturado ya existe en base de datos. Favor de capturar otro" , helpers.withAsync(async function (value) {
                    if(model.value.rfc.length == 13) {
                        const response = await validate_rfc_is_exists(model.value.rfc);
                        console.log(response)
                        return response
                    }
                    return true;
                })),
            
            },
        };

        const client_data = ref({});

        const model_update_person = ref({
            client_id:"",
            rfc:"",
            document:""
        });

        const rfc_for_view = ref("");

        const model = ref({
            rfc:""
        });
                
        onMounted( async () => {
            client_data.value = getTokenDecoden();
            rfc_without_homoclave(client_data.value.obj.person);
            const response = await get_client_data(client_data.value.obj.person);
            if(!response) return;
            clientClass.value = new Client(response);
            model_update_person.value.client_id = response.id;
            model_update_person.value.rfc = response.rfc;
        });

        function rfc_without_homoclave({rfc})
        {
            rfc_for_view.value = rfc.substr(0,10)
        }

        const model_validation = useVuelidate(rulesValidation, model.value);

        watch(showModal, ()=> {
            document.querySelector("#upload_file_button_mobile_0_rfc").addEventListener("click", () => {
                showModal.value = false;
            });
            document.querySelector("#upload_file_button_0_rfc").addEventListener("click", () => {
                showModal.value = false;
            });

            document.querySelector("#delete_file_button_mobile_0_rfc").addEventListener("click", () => {
                showModal.value = false;
            });

            document.querySelector("#delete_file_button_0_rfc").addEventListener("click", () => {
                showModal.value = false;
            });          
        });

        function open_modal()
        {
            showModal.value = true;
        }

        function concat_homoclave_with_rfc(value)
        {
            rfc_without_homoclave(client_data.value.obj.person);
            model.value.rfc = rfc_for_view.value + value.toUpperCase();
            console.log(model.value.rfc)
            model_validation.value.$validate();
        }

        async function validate_rfc_is_exists(rfc) {
            if(rfc != "") {
                return await userObjectClass.value.rfcAlreadyExists(
                    sessionStorage.getItem("login"), rfc.toString().toUpperCase())
                    .then(res => {
                    return !res.data.response.is_already_registered;})
                    .catch(err => {
                    console.log(err)
                    return false;
                });
            }
            return true;
        }

        async function uploadFile(value){

            const response = get_data_of_component();

            if(!response) return;

            model_update_person.value.rfc = response;

            if(!(await rfc_update())) return;

            const document ={
                file:value.newFile.file,
                extension:value.newFile.extension
            }

            listDocuments.value.response.data[0].doument_base64 = value.newFile.file;
            listDocuments.value.response.data[0].extension = value.newFile.extension;
            listDocuments.value.response.data[0].document_id = 1;

            refresh.value = 1;

            if(!(await upload_document(document))) return;

            api.refresh_token();

            Swal.fire({
                icon:"success",
                title:"Datos actualizados",
                text:"El R.F.C del cliente fue modificado.",
                allowOutsideClick:false
            }).then(response => {
                if(response){
                    router.push("/cuenta/principal");
                }
            });

            // console.log("xd")

        }

        function get_data_of_component()
        {
            model_validation.value.$validate();
            if(model_validation.$error){
                showModal.value = false;
                Swal.fire(
                    {
                        icon:"warning",
                        title:"Cuidado!",
                        text:"Favor de acompletar los campos correctamente.",
                        allowOutsideClick: false
                    }
                ).then( (response ) => {
                    if(response.value)
                    {
                        showModal.value = true;
                    }
                });
                return false
            }
            return model.value.rfc;
        }

        async function upload_document(new_document)
        {
            const response = await clientClass.value.upload_proof_of_tax_situation_document(new_document);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        // router.push("/cuenta/principal");
                    }
                });
                return false;
            };

            return true;
        }

        async function rfc_update() 
        {   
            isLoading.value = true;
            showModal.value = false;
            const response = await clientClass.value.update_rfc(model_update_person.value.rfc);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return true;  
        }

        async function get_client_data({id})
        {
            isLoading.value = true;
            showModal.value = false;
            const response = await Client.get_client_data(id);
            if(!response.status)
            {
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:response.message,
                    allowOutsideClick:false
                }).then(response => {
                    if(response){
                        router.push("/cuenta/principal");
                    }
                });
                return false;
            };
            isLoading.value = false;            
            return response.data;
        }

        

        return {
            showModal,
            listDocuments,
            isLoading,
            open_modal,
            model_validation,
            model,
            rfc_for_view,
            concat_homoclave_with_rfc,
            model_update_person,
            uploadFile,
            refresh
        }
    }
}

</script>