import api from '@/api/api';
const host = process.env.VUE_APP_CCAPI;
import Response from './Response';
const business = process.env.VUE_APP_BUSINESS_FONCABSA
export default class Metamap {

    getVerificationData = async (identity_id) => {
        const apiUrl = `${host}/${business}/get_verification_data`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            identity_id
        }

        try {
            const response = await await api.httpGet(apiUrl, config, params);
            return { status: true, data: response.data, message:"" }
        } catch (error) {
            throw { status: false, data: {}, message:error.response.data.mensaje } ;
        }
    }

    check_identity_verification = async ({ verification, rfc} ) => 
    {
        try 
        {
            const params = {
                mati_verification_table_type: "person",
                mati_verification_table_id: rfc,
                identity_id: verification
            };
                        
            const config = {
                headers: {
                    "Content-Type": "application/json",
                }
            };

            const response = await api.httpGet(`${host}/${business}/check_identity_verification`, { config }, params );
            
            const _response = new Response( { data: response.data } );
            _response.validate();
            
            return _response;
            
        } catch (error) {
            console.log(error)
            return { status:false, message:error.response.data.errors.join(', '), data:"" };
        }
    }

}